import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'tree__013_001_001',
  templateUrl: './tree__013_001_001.component.html',
  styleUrls: ['./tree__013_001_001.component.scss']
})
export class Tree__013_001_001 implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
