import { Component ,OnInit } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    
    <div class="socials">
      <p *ngIf="lang_ID == 2" style="font-size:13px;">تم التطوير بواسطة <a href="www.pentavalue.com">Pentavalue</a></p>
      <p *ngIf="lang_ID == 1" style="font-size:13px;">  Developed by  <a href="www.pentavalue.com">Pentavalue</a></p>

    </div>
  `,
})
export class FooterComponent implements OnInit {
  lang_ID;  

  ngOnInit() {
    this.lang_ID = localStorage.getItem("lang_id");

  }

}
