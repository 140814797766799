import { Injectable } from '@angular/core';

@Injectable({providedIn:'root'})
export class TestMenuItems {
 menuItems = {
    "status": {
        "code": 200,
        "message": "Operation completed successfully",
        "error_details": {
            "validation": [],
            "other": []
        }
    },
    "content": {
        "dashboard": [],
        "layout_type_id":1,
        "menuitems": [
            {
                "title": "Departments",
                "icon": "fas fa-server",
                "layout_id": 9,
                "page_id": 1,
                "module_id": null,
                "api": "http://appox.xyz/system/pentalibrary/public/api",
                "children": [
                    {
                        "id": 29,
                        "title": "Front-End",
                        "icon": "",
                        "layout_id": 1,
                        "page_id": 1,
                        "module_id": 48,
                        "components": [
                            {
                                "id": 1,
                                "name": "echartsBar",
                                "api": "http://appox.xyz/system/pentalibrary/public/api/requests",
                                "parameters": {
                                    "lang_id": 1,
                                    "application_id": 6,
                                    "module_id": "48",
                                    "template_id": "143"
                                },
                                "component_data": []
                            },
                            {
                                "id": 2,
                                "name": "echartsLine",
                                "api": "http://appox.xyz/system/pentalibrary/public/api/requests",
                                "parameters": {
                                    "lang_id": 1,
                                    "application_id": 6,
                                    "module_id": "48",
                                    "template_id": "143"
                                },
                                "component_data": []
                            },
                            {
                                "id": 3,
                                "name": "echartsPie",
                                "api": "http://appox.xyz/system/pentalibrary/public/api/requests",
                                "parameters": {
                                    "lang_id": 1,
                                    "application_id": 6,
                                    "module_id": "48",
                                    "template_id": "143"
                                },
                                "component_data": []
                            },
                            {
                                "id": 4,
                                "name": "list",
                                "api": "http://appox.xyz/system/pentalibrary/public/api/requests",
                                "parameters": {
                                    "lang_id": 1,
                                    "application_id": 6,
                                    "module_id": "48",
                                    "template_id": "143"
                                },
                                "component_data": []
                            },
                            {
                                "id": 10,
                                "name": "createBtn",
                                "api": "http://appox.xyz/system/pentalibrary/public/api/requests/create",
                                "parameters": {
                                    "lang_id": 1,
                                    "application_id": 6,
                                    "module_id": "48",
                                    "template_id": "141"
                                },
                                "component_data": []
                            }
                        ]
                    },
                    {
                        "id": 31,
                        "title": "Back-End",
                        "icon": "",
                        "layout_id": 1,
                        "page_id": 1,
                        "module_id": 48,
                        "components": [
                            {
                                "id": 1,
                                "name": "echartsBar",
                                "api": "http://appox.xyz/system/pentalibrary/public/api/requests",
                                "parameters": {
                                    "lang_id": 1,
                                    "application_id": 6,
                                    "module_id": "48",
                                    "template_id": "143"
                                },
                                "component_data": []
                            },
                            {
                                "id": 2,
                                "name": "echartsLine",
                                "api": "http://appox.xyz/system/pentalibrary/public/api/requests",
                                "parameters": {
                                    "lang_id": 1,
                                    "application_id": 6,
                                    "module_id": "48",
                                    "template_id": "143"
                                },
                                "component_data": []
                            },
                            {
                                "id": 3,
                                "name": "echartsPie",
                                "api": "http://appox.xyz/system/pentalibrary/public/api/requests",
                                "parameters": {
                                    "lang_id": 1,
                                    "application_id": 6,
                                    "module_id": "48",
                                    "template_id": "143"
                                },
                                "component_data": []
                            },
                            {
                                "id": 4,
                                "name": "list",
                                "api": "http://appox.xyz/system/pentalibrary/public/api/requests",
                                "parameters": {
                                    "lang_id": 1,
                                    "application_id": 6,
                                    "module_id": "48",
                                    "template_id": "143"
                                },
                                "component_data": []
                            },
                            {
                                "id": 10,
                                "name": "createBtn",
                                "api": "http://appox.xyz/system/pentalibrary/public/api/requests/create",
                                "parameters": {
                                    "lang_id": 1,
                                    "application_id": 6,
                                    "module_id": "48",
                                    "template_id": "141"
                                },
                                "component_data": []
                            }
                        ]
                    },
                    {
                        "id": 33,
                        "title": "Mobile",
                        "icon": "",
                        "layout_id": 1,
                        "page_id": 1,
                        "module_id": 11,
                        "components": [
                            {
                                "id": 1,
                                "name": "echartsBar",
                                "api": "http://appox.xyz/system/pentalibrary/public/api/requests",
                                "parameters": {
                                    "lang_id": 1,
                                    "application_id": 6,
                                    "module_id": 48,
                                    "template_id": 143
                                },
                                "component_data": []
                            },
                            {
                                "id": 2,
                                "name": "echartsLine",
                                "api": "http://appox.xyz/system/pentalibrary/public/api/requests",
                                "parameters": {
                                    "lang_id": 1,
                                    "application_id": 6,
                                    "module_id": 48,
                                    "template_id": 143
                                },
                                "component_data": []
                            },
                            {
                                "id": 3,
                                "name": "echartsPie",
                                "api": "http://appox.xyz/system/pentalibrary/public/api/requests",
                                "parameters": {
                                    "lang_id": 1,
                                    "application_id": 6,
                                    "module_id": 48,
                                    "template_id": 143
                                },
                                "component_data": []
                            },
                            {
                                "id": 4,
                                "name": "list",
                                "api": "http://appox.xyz/system/pentalibrary/public/api/requests",
                                "parameters": {
                                    "lang_id": 1,
                                    "application_id": 6,
                                    "module_id": 48,
                                    "template_id": 143
                                },
                                "component_data": []
                            },
                            {
                                "id": 10,
                                "name": "createBtn",
                                "api": "http://appox.xyz/system/pentalibrary/public/api/requests/create",
                                "parameters": {
                                    "lang_id": 1,
                                    "application_id": 6,
                                    "module_id": 48,
                                    "template_id": 141
                                },
                                "component_data": []
                            }
                        ]
                    },
                    {
                        "id": 35,
                        "title": "UI & UX ",
                        "icon": "",
                        "layout_id": 1,
                        "page_id": 1,
                        "module_id": 12,
                        "components": [
                            {
                                "id": 1,
                                "name": "echartsBar",
                                "api": "http://appox.xyz/system/pentalibrary/public/api/contact_details_branches",
                                "parameters": {
                                    "lang_id": 1,
                                    "application_id": 6,
                                    "module_id": 12,
                                    "template_id": 33
                                },
                                "component_data": []
                            },
                            {
                                "id": 2,
                                "name": "echartsLine",
                                "api": "http://appox.xyz/system/pentalibrary/public/api/contact_details_branches",
                                "parameters": {
                                    "lang_id": 1,
                                    "application_id": 6,
                                    "module_id": 12,
                                    "template_id": 33
                                },
                                "component_data": []
                            },
                            {
                                "id": 3,
                                "name": "echartsPie",
                                "api": "http://appox.xyz/system/pentalibrary/public/api/contact_details_branches",
                                "parameters": {
                                    "lang_id": 1,
                                    "application_id": 6,
                                    "module_id": 12,
                                    "template_id": 33
                                },
                                "component_data": []
                            },
                            {
                                "id": 4,
                                "name": "list",
                                "api": "http://appox.xyz/system/pentalibrary/public/api/requests",
                                "parameters": {
                                    "lang_id": 1,
                                    "application_id": 6,
                                    "module_id": 12,
                                    "template_id": 33
                                },
                                "component_data": []
                            },
                            {
                                "id": 10,
                                "name": "createBtn",
                                "api": "http://appox.xyz/system/pentalibrary/public/api/requests/create",
                                "parameters": {
                                    "lang_id": 1,
                                    "application_id": 6,
                                    "module_id": 12,
                                    "template_id": 33
                                },
                                "component_data": []
                            }
                        ]
                    },
                   
         
                ]
            },
            {
                "title": "Pages",
                "icon": "fas fa-users",
                "layout_id": 9,
                "page_id": 1,
                "module_id": null,
                "api": "http://appox.xyz/system/pentalibrary/public/api",
                "children": [
                    {
                        "id": 29,
                        "title": "About-Us",
                        "icon": "",
                        "layout_id": 1,
                        "page_id": 1,
                        "module_id": 48,
                        "components": [
                            {
                                "id": 1,
                                "name": "echartsBar",
                                "api": "http://appox.xyz/system/pentalibrary/public/api/requests",
                                "parameters": {
                                    "lang_id": 1,
                                    "application_id": 6,
                                    "module_id": "9",
                                    "template_id": "48"
                                },
                                "component_data": []
                            },
                            {
                                "id": 2,
                                "name": "echartsLine",
                                "api": "http://appox.xyz/system/pentalibrary/public/api/contact_details",
                                "parameters": {
                                    "lang_id": 1,
                                    "application_id": 6,
                                    "module_id": "9",
                                    "template_id": "26"
                                },
                                "component_data": []
                            },
                            {
                                "id": 3,
                                "name": "echartsPie",
                                "api": "http://appox.xyz/system/pentalibrary/public/api/contact_details",
                                "parameters": {
                                    "lang_id": 1,
                                    "application_id": 6,
                                    "module_id": "9",
                                    "template_id": "24"
                                },
                                "component_data": []
                            },
                            {
                                "id": 4,
                                "name": "list",
                                "api": "http://appox.xyz/system/pentalibrary/public/api/contact_details",
                                "parameters": {
                                    "lang_id": 1,
                                    "application_id": 6,
                                    "module_id": "9",
                                    "template_id": "26"
                                },
                                "component_data": []
                            },
                            {
                                "id": 10,
                                "name": "createBtn",
                                "api": "http://appox.xyz/system/pentalibrary/public/api/contact_details/create",
                                "parameters": {
                                    "lang_id": 1,
                                    "application_id": 6,
                                    "module_id": "9",
                                    "template_id": "24"
                                },
                                "component_data": []
                            }
                        ]
                    },
                    {
                        "id": 31,
                        "title": "Contact us",
                        "icon": "",
                        "layout_id": 1,
                        "page_id": 1,
                        "module_id": 48,
                        "components": [
                            {
                                "id": 1,
                                "name": "echartsBar",
                                "api": "http://appox.xyz/system/pentalibrary/public/api/requests",
                                "parameters": {
                                    "lang_id": 1,
                                    "application_id": 6,
                                    "module_id": "48",
                                    "template_id": "27"
                                },
                                "component_data": []
                            },
                            {
                                "id": 2,
                                "name": "echartsLine",
                                "api": "http://appox.xyz/system/pentalibrary/public/api/requests",
                                "parameters": {
                                    "lang_id": 1,
                                    "application_id": 6,
                                    "module_id": "10",
                                    "template_id": "27"
                                },
                                "component_data": []
                            },
                            {
                                "id": 3,
                                "name": "echartsPie",
                                "api": "http://appox.xyz/system/pentalibrary/public/api/requests",
                                "parameters": {
                                    "lang_id": 1,
                                    "application_id": 6,
                                    "module_id": "10",
                                    "template_id": "27"
                                },
                                "component_data": []
                            },
                            {
                                "id": 4,
                                "name": "list",
                                "api": "http://appox.xyz/system/pentalibrary/public/api/requests",
                                "parameters": {
                                    "lang_id": 1,
                                    "application_id": 6,
                                    "module_id": "10",
                                    "template_id": "29"
                                },
                                "component_data": []
                            },
                            {
                                "id": 10,
                                "name": "createBtn",
                                "api": "http://appox.xyz/system/pentalibrary/public/api/requests/create",
                                "parameters": {
                                    "lang_id": 1,
                                    "application_id": 6,
                                    "module_id": "10",
                                    "template_id": "27"
                                },
                                "component_data": []
                            }
                        ]
                    },
                    {
                        "id": 33,
                        "title": "History",
                        "icon": "",
                        "layout_id": 1,
                        "page_id": 1,
                        "module_id": 11,
                        "components": [
                            {
                                "id": 1,
                                "name": "echartsBar",
                                "api": "http://appox.xyz/system/pentalibrary/public/api/contact_us",
                                "parameters": {
                                    "lang_id": 1,
                                    "application_id": 6,
                                    "module_id": 11,
                                    "template_id": 30
                                },
                                "component_data": []
                            },
                            {
                                "id": 2,
                                "name": "echartsLine",
                                "api": "http://appox.xyz/system/pentalibrary/public/api/contact_us",
                                "parameters": {
                                    "lang_id": 1,
                                    "application_id": 6,
                                    "module_id": 11,
                                    "template_id": 30
                                },
                                "component_data": []
                            },
                            {
                                "id": 3,
                                "name": "echartsPie",
                                "api": "http://appox.xyz/system/pentalibrary/public/api/contact_us",
                                "parameters": {
                                    "lang_id": 1,
                                    "application_id": 6,
                                    "module_id": 11,
                                    "template_id": 30
                                },
                                "component_data": []
                            },
                            {
                                "id": 4,
                                "name": "list",
                                "api": "http://appox.xyz/system/pentalibrary/public/api/contact_us",
                                "parameters": {
                                    "lang_id": 1,
                                    "application_id": 6,
                                    "module_id": 11,
                                    "template_id": 30
                                },
                                "component_data": []
                            },
                            {
                                "id": 10,
                                "name": "createBtn",
                                "api": "http://appox.xyz/system/pentalibrary/public/api/contact_us/create",
                                "parameters": {
                                    "lang_id": 1,
                                    "application_id": 6,
                                    "module_id": 11,
                                    "template_id": 30
                                },
                                "component_data": []
                            }
                        ]
                    },
                   
                   
         
                ]
            }
        ]
    },
    "summary": {
        "filtered_by": [
            "filter 1",
            "filter 2",
            "filter 3"
        ],
        "count": {
            "displayed_rows": 39,
            "total_rows": 1200
        },
        "limit": 50,
        "pagination": 2
    }
}
}