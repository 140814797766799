import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { FormData, FieldConfig } from '../../models/field-config.interface';

@Component({
  selector: 'penta-stepper-form',
  templateUrl: './penta-stepper-form.component.html',
  styleUrls: ['./penta-stepper-form.component.scss']
})
export class PentaStepperFormComponent implements OnInit {
 

  formData: FormData= {
    formName:"test",
    fields:[
      {
        type: "input",
        label: "Username",
        inputType: "text",
        name: "name",
        value:'Hager mohammed',
        validations: [
          {
            name: "required",
            msg: "this Field Required",
            validator_value: ""
        },
        {
            name: "minlength",
            msg: "minlength For This Field is 5",
            validator_value: "5"
        },
        {
            name: "maxlength",
            msg: "maxlength for this field is 10",
            validator_value: "10"
        },
        ]
      },
      {
        type:"checkbox",
        label:"Female",
        name:"female",
        value:true,
        validations:[],
      },
    ]
  }
  regConfig:any;

  formInfo = {
      "step1": {
        "fields":[
          {
            type: "input",
            label: "Username",
            inputType: "text",
            name: "name",
            value:'Hager mohammed',
            validations: [
              {
                name: "required",
                msg: "this Field Required",
                validator_value: ""
            },
            {
                name: "minlength",
                msg: "minlength For This Field is 5",
                validator_value: "5"
            },
            {
                name: "maxlength",
                msg: "maxlength for this field is 10",
                validator_value: "10"
            },
            ]
          },
          {
            type:"checkbox",
            label:"Female",
            name:"female",
            value:true,
            validations:[],
          },
        ]    
      },
      "stp2": {
        "fields": [
          {
            type: "input",
            label: "password",
            inputType: "password",
            name: "password",
            validations: [
              {
                name: "required",
                msg: "this Field Required",
                validator_value: ""
              },
            ]
          },
        ]
      }
    }



  //   stepperForm: Array<object> = [{
  //     'step_name':'step_1',
  //     'step_label':'step1',
  //     'input_fields':[{'type':'text','required':'true','label':'name',"name":'name',
  //     "validation": [
  //       {
  //           "name": "required",
  //           "msg": "this Field Required",
  //           "validator_value": ""
  //       },
  //       {
  //           "name": "minlength",
  //           "msg": "minlength For This Field is 5",
  //           "validator_value": "5"
  //       },
  //       {
  //           "name": "maxlength",
  //           "msg": "maxlength for this field is 10",
  //           "validator_value": "10"
  //       }
  //   ]
  //   }]
  //   },

  //   {
  //     'step_name':'step_2',
  //     'step_label':'step2',
  //     'input_fields':[{'type':'text','required':'true','label':'username','name':'user_name',
  //     "validation": [
  //       {
  //           "name": "required",
  //           "msg": "this Field Required",
  //           "validator_value": ""
  //       },
  //       {
  //           "name": "minlength",
  //           "msg": "minlength For This Field is 5",
  //           "validator_value": "5"
  //       },
  //       {
  //           "name": "maxlength",
  //           "msg": "maxlength for this field is 10",
  //           "validator_value": "10"
  //       }
  //   ]
  //   }]
  //   }
  // ]

  constructor(private fb: FormBuilder) {
  }

  ngOnInit() {
    // this.regConfig = this.formInfo.step1.fields;
    let fields;
    fields = {
    formName:"test",
    fields:[
      {
        type: "input",
        label: "Username",
        inputType: "text",
        name: "name",
        value:'Hager mohammed',
        validations: [
          {
            name: "required",
            msg: "this Field Required",
            validator_value: ""
        },
        {
            name: "minlength",
            msg: "minlength For This Field is 5",
            validator_value: "5"
        },
        {
            name: "maxlength",
            msg: "maxlength for this field is 10",
            validator_value: "10"
        },
        ]
      },
      {
        type:"checkbox",
        label:"Female",
        name:"female",
        value:true,
        validations:[],
      },
    ]
    }
    this.regConfig = fields;

    console.log(this.regConfig);
  }





}
