import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';

@Component({
  selector: 'form-template2',
  templateUrl: './form-template2.component.html',
  styleUrls: ['./form-template2.component.scss']
})
export class FormTemplate2Component implements OnInit {
  @Input() tableDataFromApi;
  @Output() submitTable = new EventEmitter<{}>();
  createForm:FormGroup;
  formGroup = {};
  fields: Array<any> = [];
  header: Array<any> = [];
  tableSettings:Array<any> = [];
  formInputArray:Array<any> = [];
  constructor(private formBuilder: FormBuilder) { 
  }

  ngOnInit() {
    console.log("fields from parent =>",this.tableDataFromApi);
      this.fields = this.tableDataFromApi.fields;
      console.log("fields form template 2 => ",this.fields)
      this.header = this.tableDataFromApi.table_data.header;
      this.tableSettings = this.tableDataFromApi;
      console.log("table settings form template 2 => ",this.tableSettings)
      console.log("header form Template 2 => ",this.header)
      console.log("percentage =>")
      this.fields.forEach(element=>{
        this.formGroup[element.name] = new FormControl('');
      });

      console.log("formGroup form template 2 =>",this.formGroup);

      this.createForm = new FormGroup(this.formGroup);
  }

  submit(formValue){
      console.log("createForm =>",formValue);
      let list = [];
      this.fields.forEach(element=>{
       list.push(formValue[`${element.name}`]);
       console.log(element.type)
       if(element.inputType == 'count'){
        this.tableSettings[`table_data`].total_row.value += formValue[`${element.name}`]
        console.log("value of total row in form template 2",this.tableSettings[`table_data`].total_row.value)
      }
      })
      this.formInputArray.push(list);
      this.createForm.reset();
      this.submitTable.emit(this.formInputArray)
  }

  deleteRow(index){
      console.log(index)
      this.formInputArray.splice(index,1);
      this.submitTable.emit(this.formInputArray)
  }
}
