import { Component, OnInit } from '@angular/core';
import { Configurations } from '../../../../../consts/configurations';
import { GlobalFunctions } from '../../../../../consts/global-functions';
import { GlobalService } from '../../../../services/global/global.service';

@Component({
  selector: 'statistics__005_004',
  templateUrl: './statistics__005_004.component.html',
  styleUrls: ['./statistics__005_004.component.scss']
})
export class Statistics__005_004 implements OnInit {
  componentList;
  apiLink;
  apiParameters;
  children;
  moduleID;
  structure = {
    title: 'Revenu this quarter',
    number: 1200.000,
    ref: '$'
  }
  constructor(
    public config: Configurations,
    private GlobalFunctions: GlobalFunctions,
    private globalService: GlobalService,
  ) { }

  ngOnInit() {
    console.log("Statistics__005_004 apiLink",this.apiLink)
    console.log("Statistics__005_004 apiParameters",this.apiParameters)
    console.log("Statistics__005_004 children=> ",this.children);
    this.globalService.getResponse(this.apiLink,this.apiParameters).subscribe(response=>{
      console.log("response gggggg=> ",response);
    })
    
    this.globalService.setCompLoadedNum();
  }

}
