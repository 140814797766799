import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConstData } from '../../../../../../consts/ApiConstData';
import { Configurations } from '../../../../../../consts/configurations';
import { GlobalFunctions } from '../../../../../../consts/global-functions';
import { GlobalService } from '../../../../../services/global/global.service';

@Component({
  selector: 'key_value__014__002_001',
  templateUrl: './key_value__014__002_001.component.html',
  styleUrls: ['./key_value__014__002_001.component.scss']
})
export class Key_value__014__002_001 implements OnInit {
  apiLink;
  apiParameters;
  children;
  moduleID;
 table = {
    title:"Table of test",
    header:[],
    tableData:[
      {
        ref:"normal",
        head:'Deposite',
        data: ['50.00$'],
      },
      {
        ref:"normal",
        head:'Deposite',
        data: ['50.00$'],
      },
      {
        ref:"normal",
        head:'Deposite',
        data: ['50.00$'],
      },
      {
        ref:"normal",
        head:'Deposite',
        data: ['50.00$'],
      },
      {
        ref:"normal",
        head:'Deposite',
        data: ['50.00$'],
      },
      {
        ref:"normal",
        head:'Deposite',
        data: ['50.00$'],
      },
    ]
  }
  constructor(private httpClient:HttpClient,
              private apiConst:ApiConstData,
              public config: Configurations,
              private GlobalFunctions: GlobalFunctions,
              private globalVariables: GlobalService,
) { }

  ngOnInit() {
    console.log("Table_basic__014__001_001")
    this.httpClient.post<any>(this.apiLink,this.apiParameters,{headers:this.apiConst.getHeaderandBody().apiHeaders}).subscribe(response=>{
      console.log("respnse key value table basic=> ",response)
      this.table = response;
      this.globalVariables.setCompLoadedNum();
    },error=>{
      this.globalVariables.setCompLoadedNum();
      this.GlobalFunctions.showToastr('danger', 'Error', this.config.apiResponseMsg.danger.msg, this.config.apiResponseMsg.danger.icon)
    })
  }

}
