import { ApiConstData } from "./../../../../../consts/ApiConstData";
import { TestServiceService } from "./../../../../services/test_service/test-service.service";
import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import {
  FormArray,
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import { FormService } from "../../../../services/form/form.service";

import { GlobalFunctions } from "../../../../../consts/global-functions";
import { ActivatedRoute, Router } from "@angular/router";
import { Configurations } from "../../../../../consts/configurations";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { TranslationService } from "../../../../services/translation/translation.service";
import { MenuService } from "../../../../services/menu/menu.service";
import { NgbTimeStruct } from "@ng-bootstrap/ng-bootstrap";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: "nav_pill_add__002_005",
  templateUrl: "./nav_pill_add__002_005.component.html",
  styleUrls: ["./nav_pill_add__002_005.component.scss"],
})

export class Nav_pill_add__002_005 implements OnInit {
 
  inputFilesArr = [];
  fileBase46: string;
  fileFieldName = { name: "", type: "",extension:"" };
  filter_ref: Boolean;
  documents = [];
  objFile = {};
  appendedFieldName;
  appendFieldFiels = [];
  editorConfig = {
    editable: true,
    spellcheck: true,
    height: "100px",
    minHeight: "0",
    maxHeight: "auto",
    width: "auto",
    minWidth: "0",
    translate: "yes",
    enableToolbar: true,
    showToolbar: true,
    placeholder: "",
    defaultParagraphSeparator: "",
    defaultFontName: "",
    defaultFontSize: "",
    fonts: [
      { class: "arial", name: "Arial" },
      { class: "times-new-roman", name: "Times New Roman" },
      { class: "calibri", name: "Calibri" },
      { class: "comic-sans-ms", name: "Comic Sans MS" },
    ],
    customClasses: [],
    uploadUrl: "v1/image",
    uploadWithCredentials: false,
    sanitize: false,
    toolbarPosition: "top",
    toolbarHiddenButtons: [["bold", "italic"]],
  };

  @Input() formStructure;
  @Input() fromComponent: string;
  @Output() formResponse: EventEmitter<any> = new EventEmitter<any>();
  time: NgbTimeStruct = { hour: 13, minute: 30, second: 30 };
  seconds = true;
  addItemForm: FormGroup;
  addForm: FormGroup;
  image: string;
  maxImageSize: number = 1024;
  imageName: string;
  imageSize: number;
  fields: Array<any> = [];
  keyValueList: FormArray;
  videoLinksList: FormArray;
  extraFields: Array<any> = [];
  extraFieldGp: Array<any> = [];
  staticApi: string;
  moduleID: number;
  refMainImg: string = "";
  datawithNewFormat = {};
  images = [];
  dropdownSettings;
  mainImg;
  loading: boolean = false;
  loadingConfig: any;
  formTaleData: any;
  selectedItems = {};
  formTabs;
  lang_form: FormGroup;
  defaultLang;
  languages = [];
  tabList = [];
  langObject = {};
  apiLink: string;
  apiParameters: HttpHeaders;
  lang_id;
  chooseMainImgRef: Boolean = false;

  languageFields: Array<Object> = [];
  activeLanguageFields: Array<Object> = [];
  

  imgExt = ".gif, .jpeg, .jpg, .png";
  fileExt = ".csv, .docx, .xlsx";
  imagecheck;
  fileCheck;
  mainFile;
  children;
  componentsList;
  activeTab;
  formFilesObj = {};

  fileNameArr = [];
  fileNameAppendedArr = [];


  constructor(
    public formBuilder: FormBuilder,
    private formService: FormService,
    private GlobalFunctions: GlobalFunctions,
    private activatedRoute: ActivatedRoute,
    public config: Configurations,
    private httpClient: HttpClient,
    private apiConstData: ApiConstData,
    private testService: TestServiceService,
    private translationService: TranslationService,
    private router: Router,
    private menuService: MenuService,
    private translateService: TranslateService
  ) {
    this.lang_id = this.translationService.getLangID();
    this.translationService.setLangNDir(this.lang_id);

    // this.activatedRoute.queryParams.subscribe(params => {
    //   this.staticApi = params.staticApi;
    //   this.moduleID = params.moduleID;
    //   this.children = params.children;
    //   this.componentsList = params.componentsList;

    //   console.log("static APi from query params => ", this.staticApi);
    //   console.log("static APi from query params => ", this.moduleID);
    //   console.log("Children=> ", params.children);
    //   console.log("componentList aDDnav => ", params.componentsList);
    // })

    let sessionParams = JSON.parse(sessionStorage.getItem("queryParams"));
    this.activeTab = JSON.parse(sessionStorage.getItem("activeTab"));

    this.children = JSON.parse(sessionParams["children"]);
    this.componentsList = JSON.parse(sessionParams["componentsList"]);
    console.log("children pill=> ", this.children);
    console.log("components list in layout add item => ", this.componentsList);
    console.log("activeTabpill=> ", this.activeTab);

    this.lang_form = formBuilder.group({
      lang: [""],
    });

    /*Inside the add form 2 small forms (2 form arrays) filled this form array by function that function create control form*/
    this.addForm = formBuilder.group({
      videos: formBuilder.array([this.createVideoFields()]),
    });
  }

  ngDoCheck() {
    $(".languages_tab > ul > .nav-item").addClass("display-block");
  }

  ngAfterViewInit() {
    console.log("nav-item =>", $(".nav-item"));
    $(".languages_tab > ul > .nav-item").addClass("display-block");
    console.log("billlls", $(".languages_tab > ul > .nav-item"));
    if (this.tabList.length == 2) {
      $(".mian_tab > ul > .nav-item").addClass("width-nav-bill-2");
    }
    if (this.tabList.length == 1) {
      $(".mian_tab > ul > .nav-item").addClass("width-nav-bill-1");
    }
    if (this.tabList.length == 3) {
      $(".mian_tab > ul > .nav-item").addClass("width-nav-bill-3");
    }
    if (this.tabList.length == 4) {
      $(".mian_tab > ul > .nav-item").addClass("width-nav-bill-4");
    }
    if (this.tabList.length == 1) {
      $(".nav-pills").first().addClass("display--none");
    }
  }

  ngOnInit() {
    this.GlobalFunctions.customInput();
    console.log("====================================");
    console.log("input formStructure=> ", this.formStructure);
    console.log("====================================");
    // console.log("formFields_test services => ",this.testService.addFormFields.content)
    /*Loading Configurations*/
    this.loadingConfig = this.config.loadingConfig;
    /*dropdown Multi Select*/
    this.dropdownSettings = {
      singleSelection: false,
      idField: "value",
      textField: "name",
      selectAllText: "تحديد الكل",
      unSelectAllText: "الغاء التحديد",
      allowSearchFilter: true,
      searchPlaceholderText:'بحث'

    };

    this.getFields(this.moduleID);
    // this.getFields(41)
  }

  /*this function creats the controls of videos el mafrod el validators ana elly a7otha b2edy heana  f el case d*/
  createVideoFields(): FormGroup {
    return this.formBuilder.group({
      name: [""],
      link: [""],
    });
  }

  /*this functio creats the controls of extra fields el mafrod a7ot el validtars b2edy hena*/
  createKeyValueFields(): FormGroup {
    return this.formBuilder.group({
      key: [""],
      value: [""],
    });
  }

  appendingControl(fields): FormGroup {
    let append_fields = {};
    fields.forEach((element) => {
      return (append_fields[element.name] = new FormControl(""));
    });
    return new FormGroup(append_fields);
  }

  appendFields(form_submittion_name, fields) {
    console.log(form_submittion_name);
    console.log(fields);
    let appendFields = this.addForm.get(form_submittion_name) as FormArray;
    appendFields.push(this.appendingControl(fields));
  }

  removeFields(form_submittion_name, index) {
    let appendFields = this.addForm.get(form_submittion_name) as FormArray;
    appendFields.removeAt(index);
  }

  /**this function push the created control of video in list in form array of videos control related to the add form */
  addVideoLinkField() {
    this.videoLinksList.push(this.createVideoFields());
  }

  addContact() {
    let keyValueList = this.addForm.get("taxonomy_terms") as FormArray;
    keyValueList.push(this.createKeyValueFields());
  }

  removeContact(index) {
    let keyValueList = this.addForm.get("taxonomy_terms") as FormArray;
    keyValueList.removeAt(index);
  }

  //return all group under Key and value
  // get keyValueFormGroup() {
  //   return this.addForm.get('taxonomy_terms') as FormArray;
  // }
  get extraFieldGroup() {
    return this.addForm.get("extra_fields") as FormArray;
  }
  get videoFieldsFormGroup() {
    return this.addForm.get("videos") as FormArray;
  }

  bindValidations(validations: any) {
    if (validations.length > 0) {
      const validatorsList = [];
      validations.forEach((validator) => {
        // validList.push(valid.validator);
        if (validator.name == "minlength") {
          validatorsList.push(Validators.minLength(validator.validator_value));
        } else if (validator.name == "maxlength") {
          validatorsList.push(Validators.maxLength(validator.validator_value));
        } else {
          validatorsList.push(Validators[`${validator.name}`]);
        }
      });
      return Validators.compose(validatorsList);
    }
    return null;
  }

  test(event) {
    console.log("text_editor =>", event);
  }
  
  assignToLangForm(fields) {
    console.log("the default language =>", this.defaultLang);
    const group = this.formBuilder.group({});
    // console.log("languages fields =>>>>?//?/////", fields);

    fields.forEach((field) => {
      if (field.type === "button") return;
      const control = this.formBuilder.control(
        field.value,
        this.bindValidations(field.validations || [])
      );
      group.addControl(field.name, control);
    });
    const lang = this.formBuilder.control("");
    group.addControl("lang", lang);
    this.lang_form = group;
  }

  createExtraFields(extraFields) {
    console.log("da5al el function ", extraFields);
    let group = {};
    extraFields.forEach((element) => {
      console.log(element.name);
      console.log(element.id);

      group[element.id] = new FormControl("");
      this.extraFieldGp.push(element.id);
    });

    console.log("extra field Group", this.extraFieldGp);
    console.log("extrafield group form ", group);
    // this.addForm.get('extra_fields').setValue(group);
    return this.formBuilder.group(group);
  }

  changeLanguage(lang_id) {
    console.log("lang-idDD=> ", lang_id);
    console.log("languages fields all => ", this.languageFields);
    console.log("active lanaguae fields_1 => ", this.activeLanguageFields);
    let lang_object = {};
    this.activeLanguageFields.forEach((element) => {
      console.log("element => ", element["value"]);
      // if(element['value'] != " " || element['value']!=undefined) {
      //   console.log("hya b value")
      //   lang_object[`${element['name']}`] = element['value'];
      // }
      if (element["value"]) {
        lang_object[`${element["name"]}`] = element["value"];
        lang_object = { ...lang_object, ...{ lang: this.defaultLang } };
      } else {
        console.log("check if exist", this.langObject[`${this.defaultLang}`]);
        console.log("elemetggg=> ", element["name"]);
        let langObject = this.langObject[`${this.defaultLang}`];
        //lw el da5al el fields w b3dain mas7 w 3amal change
        if (langObject) {
          delete langObject[`${element["name"]}`];
          console.log("hagar", langObject[`${element["name"]}`]);
          console.log(
            "objectLength=> ",
            Object.keys(this.langObject[`${this.defaultLang}`]).length
          );
          if (Object.keys(this.langObject[`${this.defaultLang}`]).length == 0) {
            console.log(this.langObject);
            delete this.langObject[`${this.defaultLang}`];
          }
          console.log(
            "object length => ",
            Object.keys(this.langObject[`${this.defaultLang}`]).length
          );
        } else {
          return;
        }
      }
    });
    this.submitLangFields(lang_object);
    this.activeLanguageFields = this.languageFields[`${lang_id}`];
    this.defaultLang = lang_id;
    console.log("active lanaguae fields => ", this.activeLanguageFields);
  }

  submitLangFields(langFieldsValue) {
    console.log("lang value => ", langFieldsValue);
    let lang_id = langFieldsValue.lang;
    delete langFieldsValue.lang;
    console.log("lang_id=> ", lang_id);
    if (lang_id == undefined) {
      return;
    }
    this.langObject = { ...this.langObject, ...{ [lang_id]: langFieldsValue } };
    console.log("langObject=> ", this.langObject);
  }

  getFields(moduleID) {
    let group = {};
    let validatorsList: Array<any> = [];
    // this.formService.createCmsAddForm(moduleID, this.staticApi).subscribe(response => {
    // let response = this.formStructure;
    console.log("/*/*/*/*/********************************************");
    // console.log("response tabs content => ", response.content);
    this.formTabs = this.formStructure;
    this.apiLink = this.formTabs.api_form_submit;
    this.apiParameters = this.formTabs.parameters;
    console.log("apiLink=> ", this.apiLink);
    this.tabList = this.formStructure.tab_list;
    console.log("tabListLength => ", this.tabList.length);

    console.log("/*/*/*/*/********************************************");
    console.log("formTabs=>", this.formTabs);
    console.log("tab list =>", this.tabList);
    console.log(
      "languages gp=> ",
      this.tabList[0].tab_content.groups[0].languages.length
    );
    console.log("tabList length 2 before=> ", this.tabList.length);
    // if(this.tabList.length == 2) {
    //   console.log("tabList length 2=> ");
    //   $(".nav-item").addClass('width-nav-bill-1');
    //   console.log("nav item ",$(".nav-item"))
    //   alert("lll")
    // }

    this.tabList.forEach((element) => {
      element.tab_content.groups.forEach((gp) => {
        let fields = gp.fields;
        console.log("gp.appendGroup ", gp.append_group);

        if (gp.languages.length > 0) {
          console.log("da5al el language condition");
          this.languageFields = gp.fields;
          console.log("languageFields from condition => ", this.languageFields);
          this.defaultLang = gp.languages[0].value;
          console.log("defaultLang => ", this.defaultLang);

          this.activeLanguageFields = gp.fields[`${this.defaultLang}`];
          console.log("//*/*/*/*/*/*/*/*/*/*/");
          console.log("activeLanguageFields => ", this.activeLanguageFields);
          console.log("/*/*/*/*/*/*//*/*/*/*");
          this.assignToLangForm(gp.fields[`${this.defaultLang}`]);
          return;
        }
        if (gp.append_group == 1) {
          console.log("gp.form_submittion_name => ", gp.form_submittion_name);
          group[gp.form_submittion_name] = this.formBuilder.array([
            this.appendingControl(gp.fields),
          ]);
        }

        if (gp.append_group != 1) {
          fields.forEach((element) => {
            console.log("element validation=> ", element.validations);
            // group[element.name] = new FormControl('');
            //**********************-Key & Value-******************** */
            if (element.name == "taxonomy_terms") {
              group[element.name] = this.formBuilder.array([
                this.createKeyValueFields(),
              ]);
            } else if (element.name == "videos") {
              /**************************-Videos-******************* */
              group[element.name] = this.formBuilder.array([
                this.createVideoFields(),
              ]);
            } else if (element.name == "extra_fields") {
              /*************************-Extra Fields-************************* */
              console.log("extra field value", element.value);
              // this.createExtraFields(element.value)
              group[element.name] = this.formBuilder.array([
                this.createExtraFields(element.value),
              ]);
              this.extraFields = element.value;
              console.log(
                "=======",
                this.addForm.get("extra_fields") as FormArray
              );
            } else if (element.name == "data") {
              return;
            } else {
              /****************************************-Normal Fields-*************************** */
              console.log("elements => ", element);
              console.log("validation list  => ", element.validations);

              /**looping over the validatons list if there are the validations fr this field */
              if (element.validations.length > 0) {
                element.validations.forEach((validator) => {
                  if (validator.name == "minlength") {
                    validatorsList.push(
                      Validators.minLength(validator.validator_value)
                    );
                  } else if (validator.name == "maxlength") {
                    validatorsList.push(
                      Validators.maxLength(validator.validator_value)
                    );
                  } else if (validator.name == "pattern") {
                    validatorsList.push(
                      Validators.pattern(validator.validator_value)
                    );
                  } else if (validator.name == "required") {
                    validatorsList.push(Validators[`${validator.name}`]);
                  }
                });
              }

              group[element.name] = new FormControl(
                "",
                Validators.compose(validatorsList)
              );
            }
          });
        }

        console.log("group form object => ", group);
        this.addForm = new FormGroup(group);
        // this.keyValueList = this.addForm.get('taxonomy_terms') as FormArray;
        this.videoLinksList = this.addForm.get("videos") as FormArray;

        console.log("newfgvg=> ", this.addForm);

        console.log("fields =>", fields);
      });
    });

    // })
  }

  /**
   * this function allows you change the format of date
   * @param dateFormControlName :formControlName of date input
   */
  DateFormat(dateFormControlName) {
    let dateValue = this.addForm.get(`${dateFormControlName}`).value;
    const selectesDateTime = new Date(
      Date.parse(dateValue) - new Date().getTimezoneOffset() * 60000
    )
      .toISOString()
      .split("T")[0];
    this.datawithNewFormat[`${dateFormControlName}`] = selectesDateTime;
    console.log(this.datawithNewFormat);
  }

  /**
   * this function extracts the time from all date time format
   * @param timeFormControlName :formControlName of time input
   */
  timeFormat(timeFormControlName, ref, index, formName?) {
    console.log("timeFormControlName=> ", timeFormControlName);
    console.log("index=> ", index);
    if (ref == "formArray") {
      let testTime = this.addForm.get(`${formName}`).value[`${index}`][
        `${timeFormControlName}`
      ];
      this.addForm.get(`${formName}`).value[`${index}`][
        `${timeFormControlName}`
      ] = testTime.toString().slice(16, 21);
      console.log(
        "after=> ",
        this.addForm.get(`${formName}`).value[`${index}`][
          `${timeFormControlName}`
        ]
      );

      // this.datawithNewFormat[`${timeFormControlName}`] = testTime.toString().slice(16, 21);
      // console.log(this.datawithNewFormat);
    } else {
      let timeValue = this.addForm.get(`${timeFormControlName}`).value;
      console.log("time Format", timeValue);
      this.datawithNewFormat[
        `${timeFormControlName}`
      ] = timeValue.toString().slice(16, 21);
      // console.log("NewFormat", timeValue.toString().slice(16,21))
      console.log(this.datawithNewFormat);
    }
  }

  /**
   * this function reads the image file,converts it to base64 and injects the base64 data to datawithNewFormat object
   * @param $event : change Event to get the file from it
   * @param formControlName : the formControlName of input file in this case (Image file);
   *
   *Note (anyone work in this project please combine the two functions (readMainImage and readImage)):D
   */
  readMainImage($event: any, formControlName): void {
    console.log("Mian_file => ", this.mainFile);
    var file: File = $event.target.files[0];

    var extension = file.name
      .substring(file.name.lastIndexOf(".") + 1)
      .toLowerCase();
    this.imagecheck = this.imgExt.indexOf(extension);
    console.log("iamgeCheck => ", this.imagecheck);
    console.log("extension=> ", extension);
    var reader: FileReader = new FileReader();

    reader.onloadend = (e) => {
      this.datawithNewFormat[`${formControlName}`] = reader.result;
      this.mainFile = reader.result;
      $("#uploader-mainImg").addClass("display--none");
      $("#penta-file").val("");
      console.log("main_image => ", reader.result);
    };
    reader.readAsDataURL(file);
  }

  deleteMainImage() {
    this.mainFile = undefined;
    $("#uploader-mainImg").removeClass("display--none");
  }

  readImage(event, ref, imageFormControlName) {
    this.refMainImg = ref;
    console.log("ref of image => ", ref);
    var files = event.target.files;
    for (let i = 0; i < files.length; i++) {
      if (files) {
        this.imageName = files[i].name;
        this.imageSize = files[i].size;
        console.log("file name => ", files[i].name);
        console.log("file size => ", files[i].size);

        if (ref == "images" || ref == "files") {
          console.log("da5al el not main el ref");
          var reader = new FileReader();
          reader.onload = this.handleReaderLoaded.bind(this);
          reader.readAsBinaryString(files[i]);
        }

        if (ref == "main") {
          console.log("da5al el main ref");
          this.refMainImg = "main";
          var reader = new FileReader();
          reader.onload = this.handleReaderLoaded.bind(this);
          reader.readAsBinaryString(files[i]);
        }
      }
    }
  }

  handleReaderLoaded(readerEvt) {
    console.log("reader file  => ",readerEvt);
    var binaryString = readerEvt.target.result;
    if (this.refMainImg == "main") {
      this.mainImg = "data:image/jpeg;base64," + btoa(binaryString);
    } else if (this.refMainImg == "images") {
      this.image = "data:image/jpeg;base64," + btoa(binaryString);
      if (this.imageSize / 1000 > this.maxImageSize) {
        this.images.push({
          name: this.imageName,
          size: this.imageSize,
          index: this.images.length,
          image: this.image,
          class: "red-class",
        });
      } else {
        this.images.push({
          name: this.imageName,
          size: this.imageSize,
          index: this.images.length,
          image: this.image,
          class: "default",
        });
      }
    } else if (this.refMainImg == "files") {
      console.log("iam here files");
      this.image = "data:file;base64," + btoa(binaryString);
      if (this.imageSize / 1000 > this.maxImageSize) {
        this.documents.push({
          name: this.imageName,
          size: this.imageSize,
          index: this.images.length,
          image:
            "https://icon-library.com/images/file-icon-png/file-icon-png-12.jpg",
          file: this.image,
          class: "red-class",
        });
      } else {
        this.documents.push({
          name: this.imageName,
          size: this.imageSize,
          index: this.images.length,
          link: this.image,
          image:
            "https://icon-library.com/images/file-icon-png/file-icon-png-12.jpg",
          class: "default",
        });
      }
    }
    console.log("image Name => ", this.imageName);

    // this.images.push(this.image);
  }

  closebtn(index, ref) {
    console.log("closebtn");
    console.log("value2");
    if (ref == "doc") {
      this.documents.splice(index, 1);
      $.each(this.documents, function (id, value) {
        value.index = id;
      });
    } else if (ref == "image") {
      this.images.splice(index, 1);
      $.each(this.images, function (id, value) {
        value.index = id;
      });
    }
  }

  onItemSelect(item: any) {
    console.log(item.value);
  }

  onSelectAll(items: any) {
    console.log(items.value);
  }

  getTableData(event) {
    this.formTaleData = event;
  }

  changeSelect(event, name, base, gpIndex, tabIndex, field) {
    console.log("before remove form=> ", this.addForm.controls);

    console.log("field from option => ", field);
    console.log("tabindex=> ", tabIndex);
    if (field.has_toggle_fields == 1) {
      this.loading = true;
      let body = {};
      body[`${field.name}`] = event.target.value;
      this.httpClient
        .post(`${field.toogle_fields_api}`, body, {
          headers: this.apiConstData.getHeaderandBody().apiHeaders,
        })
        .subscribe(
          (response) => {
            console.log("response from toggle=> ", response);
            console.log("tab list=> ", this.tabList);
            /* create form Control*/
            let group = {};
            let validatorsList: Array<any> = [];

            this.tabList[tabIndex].tab_content.groups[gpIndex].fields.forEach(
              (element) => {
                this.addForm.removeControl(element.name);
              }
            );
            console.log("after remove form=> ", this.addForm);
          
            console.log("new tabList=> ", this.tabList);
            this.tabList.forEach((element) => {
              element.tab_content.groups.forEach((gp) => {
                let fields = gp.fields;
                if (gp.languages.length > 0) {
                  console.log("da5al el language condition");
                  this.languageFields = gp.fields;
                  console.log(
                    "languageFields from condition => ",
                    this.languageFields
                  );
                  this.defaultLang = gp.languages[0].value;
                  console.log("defaultLang => ", this.defaultLang);

                  this.activeLanguageFields = gp.fields[`${this.defaultLang}`];
                  console.log("//*/*/*/*/*/*/*/*/*/*/");
                  console.log(
                    "activeLanguageFields => ",
                    this.activeLanguageFields
                  );
                  console.log("/*/*/*/*/*/*//*/*/*/*");
                  this.assignToLangForm(gp.fields[`${this.defaultLang}`]);
                  return;
                }
                if (gp.append_group == 1) {
                  group[gp.form_submittion_name] = this.formBuilder.array([
                    this.appendingControl(gp.fields),
                  ]);
                }
                if (gp.append_group != 1) {
                  fields.forEach((element) => {
                    console.log("element validation=> ", element.validations);
                    // group[element.name] = new FormControl('');
                    //**********************-Key & Value-******************** */
                    if (element.name == "taxonomy_terms") {
                      group[element.name] = this.formBuilder.array([
                        this.createKeyValueFields(),
                      ]);
                    } else if (element.name == "videos") {
                      /**************************-Videos-******************* */
                      group[element.name] = this.formBuilder.array([
                        this.createVideoFields(),
                      ]);
                    } else if (element.name == "extra_fields") {
                      /*************************-Extra Fields-************************* */
                      console.log("extra field value", element.value);
                      // this.createExtraFields(element.value)
                      group[element.name] = this.formBuilder.array([
                        this.createExtraFields(element.value),
                      ]);
                      this.extraFields = element.value;
                      console.log(
                        "=======",
                        this.addForm.get("extra_fields") as FormArray
                      );
                    } else if (element.name == "data") {
                      return;
                    } else {
                      /****************************************-Normal Fields-*************************** */
                      console.log("elements => ", element);
                      console.log("validation list  => ", element.validations);

                      /**looping over the validatons list if there are the validations fr this field */
                      if (element.validations.length > 0) {
                        element.validations.forEach((validator) => {
                          if (validator.name == "minlength") {
                            validatorsList.push(
                              Validators.minLength(validator.validator_value)
                            );
                          } else if (validator.name == "maxlength") {
                            validatorsList.push(
                              Validators.maxLength(validator.validator_value)
                            );
                          } else if (validator.name == "pattern") {
                            validatorsList.push(
                              Validators.pattern(validator.validator_value)
                            );
                          } else if (validator.name == "required") {
                            validatorsList.push(
                              Validators[`${validator.name}`]
                            );
                          }
                        });
                      }

                      group[element.name] = new FormControl(
                        "",
                        Validators.compose(validatorsList)
                      );
                    }
                  });
                }

                console.log("group form object => ", group);
                this.addForm = new FormGroup(group);
                this.keyValueList = this.addForm.get(
                  "taxonomy_terms"
                ) as FormArray;
                this.videoLinksList = this.addForm.get("videos") as FormArray;

                console.log("newfgvg=> ", this.addForm);

                console.log("fields =>", fields);
              });
            });
            this.loading = false;
          },
          (error) => {
            this.loading = false;
          }
        );
    } else {
      let fieldsOfGp = this.formTabs.tab_list[tabIndex].tab_content.groups[
        gpIndex
      ].fields;
      this.selectedItems[`${name}`] = { value: `${event.target.value}` };
      fieldsOfGp.forEach((element) => {
        console.log("element.base =>", element.base);
        if (element.base == `${name}`) {
          console.log("element /*/*/*/*/*/*/*8/8/8/8/8=> ", element);
          let object = {};
          object[`${name}`] = event.target.value;
          console.log("object selet =>", object);
          let body = {
            ...this.apiConstData.getHeaderandBody().apiBody,
            ...object,
          };
          this.loading = true;
          this.httpClient
            .post<any>(`${element.target_url}`, body, {
              headers: this.apiConstData.getHeaderandBody().apiHeaders,
            })
            .subscribe((response) => {
              console.log("dsjjds", response);
              element.options = response;
              this.loading = false;
            });
        }
      });
    }

    console.log("selected items=> ", this.selectedItems);
  }

  readFile(event, imageFormControlName,i?) {
    console.log("ttttt",i)
    this.fileNameArr[i] = event.target.files[0].name;
    console.log(this.fileNameArr)
    var files = event.target.files;
    $(`${imageFormControlName}_filename`).append(`${files[0].name}`);
    console.log(`${imageFormControlName}_filename`)
    console.log("uuuuu=> ",files[0].name);
    
    this.fileFieldName.name = imageFormControlName;
    for (let i = 0; i < files.length; i++) {
      if (files) {
        var extension;
        extension = files[i].name
          .substring(files[i].name.lastIndexOf(".") + 1)
          .toLowerCase();
        this.imagecheck = this.imgExt.indexOf(extension);
        this.fileCheck = this.fileExt.indexOf(extension);
        console.log("imageCheck=> ", this.imagecheck);
        console.log("fileCheck=> ", this.fileCheck);
        if(this.imagecheck > 0) {
          this.fileFieldName.type = "image";
          this.fileFieldName.extension = extension 
        };
        if(this.fileCheck > 0){
          this.fileFieldName.type = "file";
          this.fileFieldName.extension = extension 

        }
        // console.log("image Extenstion => ",this.imgExt.indexOf(extension))

        var reader = new FileReader();
        reader.onload = this.handleReaderLoadedFile.bind(this);
        reader.readAsBinaryString(files[i]);
      }
    }
  }
  
  handleReaderLoadedFile(readerEvt) {
    console.log("reader File=>",readerEvt)
    var binaryString = readerEvt.target.result;
    "data:image/jpeg;base64," + btoa(binaryString);
    if(this.fileFieldName.type == "image") {
      var file = "data:image/jpeg;base64," +btoa(binaryString);
    }
    else {
      console.log("current extension=> ",this.fileFieldName.extension);
      var file = `data:application/${this.fileFieldName.extension};base64,` +btoa(binaryString);
    }
    console.log("currentFileField => ", this.fileFieldName);
    console.log("files=> ", file);
    
    this.fileBase46 = file;
    console.log("before objFile-> ", this.objFile);
    this.objFile[`${this.fileFieldName.name}`] = file;
    console.log("After objFile-> ", this.objFile);

  }

  appendedFiles(event, fieldName, i,formName)
  {
    this.fileNameAppendedArr[i] =  event.target.files[0].name;
      console.log("append event=> ",event);
      console.log("append fieldName=> ",fieldName);
      console.log("append Index=> ",i);
      console.log("formName=>> ",formName);
      this.appendedFieldName = formName;


      let file = event.target.files[0];
      let reader = new FileReader();
      var obj ={}
      // this.addForm.get(`${formName}`).value[i][`${fieldName}`] = "tttttt";

      reader.readAsDataURL(file);
      reader.onload = (e:any)=>{
        this.appendFieldFiels[i] = obj;
        obj[`${fieldName}`] = e.target.result ;
        console.log("appendFieldFiels", this.appendFieldFiels)

        // $(`append_file_${i}`).val(`${test}`)
        // console.log(this.addForm.get(`${formName}`).value);
        // return;
      }

  }

  /**
   * this function try to submit the values form to api
   * @param formValue : value of input fields
   */
  submit(formValue) {
    console.log("filesListArray=> ", this.inputFilesArr);
    console.log("apiLink from nav_pill_add => ", this.apiLink);
    console.log("apiParameters from nav_pill_add => ", this.apiParameters);
    console.log("Date formate => ", formValue);
    if(this.appendFieldFiels.length > 0) {
      this.addForm.controls[`${this.appendedFieldName}`].value.forEach((element,index)=>{
        element = {...element,...this.appendFieldFiels[index]}
      })
    }

    let lang_object = {};
    //by5od el active languages fields 3lshan mafesh submit
    this.activeLanguageFields.forEach((element) => {
      console.log("element => ", element["value"]);

      if (element["value"]) {
        lang_object[`${element["name"]}`] = element["value"];
        lang_object = { ...lang_object, ...{ lang: this.defaultLang } };
      }
    });

    this.submitLangFields(lang_object);

    formValue[`images`] = this.images;
    formValue[`documents`] = this.documents;

    // formValue[`files`] = this.fields;
    formValue[`module_id`] = this.moduleID;
    formValue[`formTableData`] = this.formTaleData;
    formValue[`lang_fields`] = this.langObject;

    // formValue[`name`] = "test"
    console.log("objFile=> ", this.objFile);
    formValue = {
      ...formValue,
      ...this.objFile,
      ...this.datawithNewFormat,
      ...this.apiParameters,
    };
    console.log("new form Value", formValue);
    console.log("images => ", this.images);
    console.log("apiLink for submit=>", this.apiLink);
    this.loading = true;
    this.httpClient
      .post<any>(`${this.apiLink}`, formValue, {
        headers: this.apiConstData.getHeaderandBody().apiHeaders,
      })
      .subscribe(
        (response) => {
          console.log("submit response => ", response.status.code);
          console.log("response => ", response);
          this.loading = false;
          if(response.status.code == 401){
            localStorage.clear();
            sessionStorage.clear();
            this.router.navigate(['./login'])
        }
        else {
          this.GlobalFunctions.handelErrorCode(
            response.status.code,
            response.status.validation_errors,
            `${response.status.message}`
          );

          // this.formResponse.emit(true);
          console.log("2abel aya 7aga ");
          if (this.fromComponent != "modal" && response.status.code == 200) {
            
            if (this.filter_ref) {
              let filter_list = {
                api: this.apiLink,
                parameters: formValue,
              };
              sessionStorage.setItem(
                "filter_list",
                JSON.stringify(filter_list)
              );
              console.log(
                "session Nav=> ",
                sessionStorage.getItem("filter_list")
              );
              // window.location.reload();
            }
            this.addForm.reset();
            let navigationLink = this.menuService.getLayoutByID(
              this.activeTab.layout_id
            );
            this.loading = false;

            console.log("navigation link=> ", navigationLink);
            setTimeout(() => {
              this.router.navigate([`${navigationLink}`], {
                queryParams: {
                  children: this.children,
                  moduleID: this.moduleID,
                  componentsList: this.componentsList,
                },
              });
            }, 1000);
          }
        }
         
        },
        (error) => {
          this.loading = false;
          if (error.status == 0) {
            this.GlobalFunctions.showToastr(
              "danger",
              this.translateService.instant('ERROR'),
              "No Internet Connection, Please try again",
              this.config.apiResponseMsg.danger.icon
            );
          }
          this.GlobalFunctions.showToastr(
            "danger",
            "Please try again",
            this.translateService.instant('ERROR'),
            "fas fa-times"
          );
          console.log("formResponse => false");
          this.formResponse.emit(false);
        }
      );
    // this.formService.submitAddForm(formValue, this.staticApi).subscribe(response => {
    //   console.log("response", response);
    //   console.log("status =>", response.status)
    //   this.addForm.reset();
    //   this.GlobalFunctions.showToastr('success', 'the Form Submitted Successfully', 'Success', 'fas fa-check')
    // }, err => {
    //   console.log("error", err);
    //   this.GlobalFunctions.showToastr('danger', 'Please try again', 'Error', 'fas fa-times')
    // })
  }
}
