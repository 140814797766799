import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Nav_pill_add__002_005 } from '../../_#002__forms/_comp__#002_#005__nav_pill_add/nav_pill_add__002_005.component';
import { GlobalService } from '../../../../services/global/global.service'
import { ApiConstData } from '../../../../../consts/ApiConstData'
import { LayoutItemAddComponent } from '../../../../pages/layouts/layout-item-add/layout-item-add.component';
@Component({
  selector: 'filter-btn__019_001',
  templateUrl: './filter-btn__019_001.component.html',
  styleUrls: ['./filter-btn__019_001.component.scss']
})
export class FilterBtn__019_001 implements OnInit {
  @Input() apiLink;
  @Input() apiParameters;
  @Input() moduleID;
  @Output() filterStatus: EventEmitter<any> = new EventEmitter<any>();

  constructor(
              private modal: NgbModal,
              private globalService: GlobalService,
              public apiConstData: ApiConstData,

  ) { }

  ngOnInit() {
    console.log("filter apiLink=> ", this.apiLink);
    console.log("filterapiParameter=> ",this.apiParameters)
  }
  filter() {
    console.log("filter")
    this.globalService.getResponse(this.apiLink,this.apiParameters,{headers:this.apiConstData.getHeaderandBody().apiHeaders}).subscribe((response:any)=>{
      console.log("response filter => ",response.content.tab_list);
      const activeModal = this.modal.open(LayoutItemAddComponent, { size: 'lg', container: 'nb-layout' });
      activeModal.componentInstance.staticApi = this.apiLink;
      activeModal.componentInstance.apiParameters = this.apiParameters;
      activeModal.componentInstance.filter_ref = true;
      activeModal.componentInstance.formResponse.subscribe(formStatus=>{
        console.log("formstatus filter=> ",formStatus)
        this.filterStatus.emit(formStatus)
      })
    })
  }

}
