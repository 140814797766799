import { HttpClient } from '@angular/common/http';
import { TestServiceService } from './../../../../services/test_service/test-service.service';
import { Component, OnInit, HostListener } from '@angular/core';
import { ApiConstData } from '../../../../../consts/ApiConstData';
import { GlobalFunctions } from '../../../../../consts/global-functions';
import { Configurations } from '../../../../../consts/configurations';
import { GlobalService } from '../../../../services/global/global.service';

@HostListener("window:scroll", [])
@Component({
  selector: 'log__006_002',
  templateUrl: './log__006_002.component.html',
  styleUrls: ['./log__006_002.component.scss']
})
export class Log__006_002 implements OnInit {
  logStructure;
  apiLink: string;
  apiParameters: Object;
  moduleID: number;
  logData;
  constructor(
              private testService: TestServiceService,
              private apiConstData:ApiConstData,
              private httClient: HttpClient,
              public config: Configurations,
              private globalVariables: GlobalService,
              private GlobalFunctions: GlobalFunctions,

              
  ) { }

  ngOnInit() {
    // this.logData = [{
    //   "time": "",
    //   "date": "",
    //   "log_content": [
    //     {
    //       "type": "",
    //       "content": "",
    //     },
    //   ]
    // },]
    console.log("log__006_002_(apiLink)=> ",this.apiLink)
    console.log("log__006_002_(apiParameters)=> ",this.apiParameters)
    console.log("log__006_002_(moduleID)=> ",this.moduleID)
    console.log("logggggghhghghghgghghghghgh")

    // this.logData = this.testService.logStructure;
    // console.log("log structure =>",this.logData);

    this.httClient.post<any>(`${this.apiLink}`,this.apiParameters,{headers: this.apiConstData.getHeaderandBody().apiHeaders}).subscribe(response=>{
      this.globalVariables.setCompLoadedNum();
      console.log("log_CompLoadedNum=> ",this.globalVariables.compLoadedNum);
      console.log("log_response => ",response)
      console.log("log__006_002_response =>",response.logs_structure);
      this.logData = response.logs_structure;
      console.log("logData=> ",this.logData)

    },error=>{
      console.log("logError")

      this.globalVariables.setCompLoadedNum();
      this.GlobalFunctions.showToastr('danger', 'Error', this.config.apiResponseMsg.danger.msg, this.config.apiResponseMsg.danger.icon)
      console.log("log_CompLoadedNum=> ",this.globalVariables.compLoadedNum);

    })
  }
  chk_scroll(e){
    var elem = $(e.currentTarget);
    if (elem[0].scrollHeight - elem.scrollTop() == elem.outerHeight())
    {
      console.log("bottom");
    }

}
}
