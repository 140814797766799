import { BASE_API_URL } from './../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { TestServiceService } from '../../../../services/test_service/test-service.service';

@Component({
  selector: 'tabs__001_001',
  templateUrl: './tabs__001_001.component.html',
  styleUrls: ['./tabs__001_001.component.scss']
})
export class Tabs__001_001 implements OnInit {
  baseURL = BASE_API_URL;
  tabs;
  constructor(private testService: TestServiceService,
              private httpClient: HttpClient,
    ) { }

  ngOnInit() {
    //  this.tabs = this.testService.generalTab_without_form_style();
     console.log("tabs Structure => ", this.tabs);
  }

  submitForm(formValue,apiLink){
      console.log("apiLink=>", apiLink)
      console.log("formmm =>",formValue)
      //when submit call api to submit this form on it 
      // this.httpClient.post<any>(`${this.baseURL}${apiLink}`,formValue);      
  }

}
