import { Component, OnInit, Input } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { DomSanitizer } from '@angular/platform-browser';



@Component({
  selector: 'penta-media-input',
  templateUrl: './media-input.component.html',
  styleUrls: ['./media-input.component.scss']
})
export class MediaInputComponent implements OnInit {
  @Input() data;
  customOptions: OwlOptions;
  rtlRef = false;

  constructor(public sanitizer: DomSanitizer) { }

  ngOnInit() {
      console.log("image=> ",this.data)
      this.customOptions = {
        loop: true,
        margin: 30,
        nav: true,
        navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"],
        mouseDrag: false,
        touchDrag: false,
        pullDrag: false,
        rtl: true,
        dots: false,
        navSpeed: 500,
        responsive: {
          0: {
            items: 1
          },
          400: {
            items: 1
          },
          740: {
            items: 1
          },
          940: {
            items: 1
          }
        }
    
      }
    
  }

  formatVideoURL(videoLink: string) {
    let videoId;
    if(videoLink.indexOf('youtube') > -1 ) {
      videoId = videoLink.split('watch?v=')[1];
      return `https://www.youtube.com/embed/${videoId}`
    } 
    else if(videoLink.indexOf('vimeo.com') > -1 ) {
      videoId = videoLink.split('vimeo.com')[1];
      return `https://player.vimeo.com/video${videoId}`
    }
    else {
      return videoLink;

    }

  }

}
