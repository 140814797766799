import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ActiveComponentsService {
  activeComponents:Array<any> = []
  constructor() { }

  setActiveComponents(components) {
    this.activeComponents = components

  }
  getActiveComponents() {
    return this.activeComponents;
  }

}
