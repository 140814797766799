import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { PentaDynmaicFormComponent } from '../../../penta-form-components/penta-dynmaic-form/penta-dynmaic-form.component';

@Component({
  selector: 'basic-form',
  templateUrl: './basic-form.component.html',
  styleUrls: ['./basic-form.component.scss']
})
export class BasicFormComponent implements OnInit {
  @ViewChild(PentaDynmaicFormComponent) formGp: PentaDynmaicFormComponent;

  @Input() formData: FormData;
  constructor() { }

  ngOnInit() {
  }

}
