import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';
// import { LayoutModalComponent } from './../../../../pages/layouts/layout-modal/layout-modal/layout-modal.component';
import { GlobalService } from './../../../../services/global/global.service';
import { MenuService } from './../../../../services/menu/menu.service';
import { ListService } from './../../../../services/list/list.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'card__003_001',
  templateUrl: './card__003_001.component.html',
  styleUrls: ['./card__003_001.component.scss']
})
export class Card__003_001 implements OnInit {
  status;
  allListItems; 
  headerList = [];
  contentList = [];
  basicData = [];
  basicBtn = [];
  bulkActions = [];
  selectedRowsData = [];
  selectedRowsID = []
  excelFile;
  excelHeader;
  countIndex:number = 0;
  moduleID;
  apiLink;
  allItems;
  constructor(
              private router:Router,
              public modal : NgbModal,

              private listService:ListService,
              private menuService:MenuService,
              private globalVar: GlobalService,

  ) { }

  ngOnInit() {
    //Get data from API
    // this.globalVars.fromDataTable = false; //ref to know that data comes from datatable
    // this.allListItems = this.listService.listItem; //list object(From API)
    // this.basicData = this.allListItems.content.content[0].basic_data; //(From API)
    // this.headerList = this.allListItems.content.header;//(From API)
    // this.bulkActions = this.allListItems.content.bulk_actions;
    // this.basicBtn = this.allListItems.content.content[0].basic_button;
    console.log("*/*/*/*/*api Link /**/*/* => ", this.apiLink);
    console.log("*/*/*/*/Module ID /**/*/* => ", this.moduleID);
   
    this.getData();
  }


  getData() {
    console.log("cardsssss/*/*/")
    this.listService.getListModule(this.moduleID,this.apiLink,3,1).subscribe(response => {
      this.globalVar.setCompLoadedNum();
      this.allListItems = response;
      this.basicData = this.allListItems.content.basic_data;
      this.basicBtn = this.allListItems.content.basic_button;
      this.headerList = this.allListItems.content.header;
      this.bulkActions = this.allListItems.content.bulk_actions;
      this.allItems = this.allListItems.summary.count.total_rows;
      console.log("basic Button => ",this.basicBtn);
      console.log("bulk actions => ",this.bulkActions);
      console.log("basic Data => ",this.basicData[0])
     
    })
  }

   
  /**
    *this function try to handle actions on any item of allListItems
    *actions : (layout => Go to Page so we need the layoutID and The pageID)
    *        : (modal => fire modal so we need the pageID)
    *        : (URL => Go to External Link so we need the targetURL)
    * @param settings : settings of item in allListItems To get all settings to handel the actions 
   */
  handelTarget(settings){
    if(settings.target_type == 'layout'){
          let layoutID = settings.target_layout_id;
          let pageID = settings.page_id;
          let layoutLink = this.menuService.getLayoutByID(layoutID)
          this.router.navigate([layoutLink,{'pageID':pageID}])
    }

    if(settings.target_type == 'modal'){
      let pageID = settings.page_id;
      // const activeModal = this.modal.open(LayoutModalComponent);
      // activeModal.componentInstance.pageID = pageID
    }
    if(settings.target_type == 'url'){
      let URL = settings.target_url;
      window.open(URL, "_blank");
    }
    
  }

  /**
   * this function try to handel the bulk actions that will applay to selected itemes in allListItems
   * @param actionName : the name of action (get from API)
   * actions : (DeleteSelected => delete Selected Items from list(allListItems))
   *         : (Activate Selected => Activate selected Items from List(allListItems))
   *         :(Export Excel => Export Selected Rows to Excel File)
   *         :(Print => Print Selected rows)
   */
  bulkAction(actionName){
    console.log(actionName)
    if(this.selectedRowsData.length > 0){
      //==================-Delete Selected-===================
      if(actionName == 'delete-selected'){
        console.log("delete selected")
        //-================-Call delete Api-===============
      }
      if(actionName == 'activate-selected'){
         //-================-Call Activate Api-===============
      }

      if(actionName == 'export-excel'){
        console.log(this.selectedRowsData)
        let excelHeader = []
        this.headerList.forEach(element =>{
          excelHeader.push(element.name)
        })
        var options = { 
          fieldSeparator: ',',
          quoteStrings: '"',
          showLabels: true, 
          showTitle: false,
          title: 'Your title',
          useBom: true,
          noDownload: false,
          headers:excelHeader,
          nullToEmptyString: true,
        };
       
        this.excelFile = new Angular5Csv(this.selectedRowsData, 'Report', options); 
        this.selectedRowsData = []; 
        
        
      }

      if(actionName == 'print'){
          //3awza ashoflha 7al tany
        }
    }
    else{
      alert("please select rows ")
    }
  }

  /**
   * this Functions try add all selected rows to array to use it 
   * @param rowData : the Data of selected row(Array)
   * @param index : the index of item in array to splice or add the item from the array
   */

  addSelectedRow(rowData,index){
    var element = document.getElementById(`card_${index}`);
    element.classList.toggle('select-card');
    
    var object = {};
    console.log("status => ",this.status)
      console.log("Row Data => ",rowData);

      if(element.classList.contains('select-card')){
        rowData.push({'value':this.countIndex,settings:{'title':'item_index'}})
        rowData.forEach(element => {
          let key = element.settings.title;
          object[`${key}`] = element.value;
        });
        this.countIndex +=1;
        this.selectedRowsData.push(object);
      }
      else{
        this.countIndex -=1;
        this.unSelectedItem(this.selectedRowsData,rowData[rowData.length - 1].value);
      }
    
    
      
      console.log("Selected Rows Data => ",this.selectedRowsData)
      console.log("rowadtat after selected => ",rowData)
      // this.selectedRowsID.push(rowID);
  }

  /**
   * This Function delete the unselected row from selectedRowData
   * @param index: index of item that will be removed from selectedRowData
   * @param arr: the array that contains all selected item 
   */
  unSelectedItem(arr,index){
    arr.splice(index,index)
  }

  basicBtnAction(id,action){
      console.log("action=> ",action)
        if(action.target_type == 'layout'){
          let layoutID = action.target_layout_id;
          let pageID = action.page_id;
          let layoutLink = this.menuService.getLayoutByID(layoutID)
          this.router.navigate([layoutLink,{'pageID':pageID}])
    }

    if(action.target_type == 'modal'){
      let pageID = action.page_id;
      // const activeModal = this.modal.open(LayoutModalComponent);
      // activeModal.componentInstance.pageID = pageID
    }
    if(action.target_type == 'url'){
      let URL = action.target_url;
      window.open(URL, "_blank");
  }
  }

}
