import { Component, OnInit } from '@angular/core';
import { Configurations } from '../../../../../consts/configurations';
import { GlobalFunctions } from '../../../../../consts/global-functions';
import { GlobalService } from '../../../../services/global/global.service';

@Component({
  selector: 'statistics__005_003',
  templateUrl: './statistics__005_003.component.html',
  styleUrls: ['./statistics__005_003.component.scss']
})
export class Statistics__005_003 implements OnInit {
  apiLink;
  apiParameters;
  children;
  moduleID;
  componentList
  structure = {
    title: "Opportunity",
    header: [
      {
        icon:'far fa-thumbs-down',
        number:70,
        text: 'Active',
        color:'#628CED',

      },
      {
        icon:'far fa-thumbs-up',
        number:34,
        text: 'Active',
        color:'#E87171',

      },
      {
        icon:'far fa-clock',
        number:90,
        text: 'Active',
        color:'#3DC094',
      },
      {
        icon:'far fa-check-circle',
        number:45,
        text: 'Active',
        color:'#628CED',
      },
      
    ],
    data:[
      {
        text:'Opportunity Description 1',
        number:'233.233.000',
        ref:'LE',
        status:[
          {
            name:'Active',
            color:'#628CED'
          },
          {
            name:'Hot',
            color:'#E87171'
          },
          {
            name:'Industry',
            color:'#3DC094'
          },
        ]
      
      },
      {
        text:'Opportunity Description 1',
        number:'233.233.000',
        ref:'LE',
        status:[
          {
            name:'Active',
            color:'#628CED'
          },
          {
            name:'Hot',
            color:'#E87171'
          },
          {
            name:'Industry',
            color:'#3DC094'
          },
        ]      },
      {
        text:'Opportunity Description 1',
        number:'233.233.000',
        ref:'LE',
        status:[
          {
            name:'Active',
            color:'#628CED'
          },
          {
            name:'Hot',
            color:'#E87171'
          },
          {
            name:'Industry',
            color:'#3DC094'
          },
        ]      },
    ]
  }
  constructor(
    public config: Configurations,
    private GlobalFunctions: GlobalFunctions,
    private globalVariables: GlobalService,
  ) { }

  ngOnInit() {
    console.log("Statistics__005_004 apiLink",this.apiLink)
    console.log("Statistics__005_004 apiParameters",this.apiParameters)
    console.log("Statistics__005_004 children=> ",this.children)
    console.log(" Statistics__005_003 apiLink => ", this.apiLink)
    console.log(" Statistics__005_003 apiParameters => ", this.apiParameters)
    this.globalVariables.setCompLoadedNum();

  }

}
