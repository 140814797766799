import { BASE_API_URL } from './../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { ListService } from './../../services/list/list.service';
import { GlobalService } from '../../services/global/global.service';
import { MenuService } from '../../services/menu/menu.service';

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
// import { LayoutModalComponent } from '../../pages/layouts/layout-modal/layout-modal/layout-modal.component';
import { TableComponent } from '../list/table/table.component';
import { ApiConstData } from '../../../consts/ApiConstData';
import { GlobalFunctions } from '../../../consts/global-functions';
import { Configurations } from '../../../consts/configurations';
import { ActiveComponentsService } from '../../services/active-components/active-components.service';


@Component({
  selector: 'ngx-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss']
})
export class ActionsComponent implements OnInit {
  actions = [];
  allListItems;
  basicBtns = [];
  tableActionsBtn = [];
  rowID;
  api = BASE_API_URL;
  headers:HttpHeaders;
  apiLink;
  moduleID;
  constBody;
  apiResponseMsg:any = this.config.apiResponseMsg;
  constructor(private router : Router,
              private modal : NgbModal,

              private globalVar:GlobalService,
              private listService:ListService,
              private menuService:MenuService,
              private httpClient:HttpClient,
              private apiConstData: ApiConstData,
              public globalFunction:GlobalFunctions,
              public config: Configurations,
              private activeComponents: ActiveComponentsService,
            
    ) { 
      this.headers = apiConstData.getHeaderandBody().apiHeaders;
      this.constBody = apiConstData.getHeaderandBody().apiBody;
    }

  ngOnInit() {
    this.rowID = this.globalVar.rowID.id
    this.moduleID = this.globalVar.moduleID;
    this.apiLink = this.globalVar.staticApi;

    console.log("===================================")
    console.log("rowID",this.rowID)
    console.log("===================================")


    this.actions = this.globalVar.actionsBtn;
    console.log("actionsbtns=> ",this.actions)

    console.log(this.actions)
    this.actions.forEach((element,index)=>{
        
          element["rowID"] = this.rowID
          // this.tableActionsBtn.push(basicBtn);
        
    })
    console.log("final Action ber row");
    console.log(this.actions)
    console.log(this.rowID)
  }
  actionFunc(item){
    console.log('====================================');
    console.log("test actions=>", item);
    console.log('====================================');
    if (item.target_type == 'layout'){
      let layoutLink = this.menuService.getLayoutByID(item.target_layout_id)
      
      this.router.navigate([layoutLink],{queryParams:{itemID:item.rowID.value,apiLink:item.target_url,apiParameters:JSON.stringify(item.parameters)}})
      // this.router.navigate(['/pages/layouts/layout-edit'])
    }
    if(item.target_type == 'modal'){
      // const activeModal = this.modal.open(LayoutModalComponent, { size: 'lg', container: 'nb-layout' });
      // activeModal.componentInstance.pageID = item.page_id;
      
    }
    /*Incase of api action like delete*/
    if(item.target_type == 'api'){
      // console.log("activeComponents=> ",this.activeComponents.getActiveComponents())
      // console.log("item target_url => ",item.target_url)
      let itemID = item.rowID.value;
      let apiURL = item.target_url;
      console.log("delete=> ",apiURL)
      let body ={...this.constBody,...{"id" :itemID}} 
      this.httpClient.request('post',`${apiURL}`,{body:body,headers:this.headers}).subscribe(res=>{
        console.log(res);
        this.router.navigateByUrl('/RefrshComponent', {skipLocationChange: false}).then(()=>
                this.router.navigate(['/pages/layouts/layout1'],{queryParams:{moduleID:this.moduleID,componentsList:this.activeComponents.getActiveComponents()}})); 
      },error=>{
        console.log("error => ",error)
        this.globalFunction.showToastr('danger','Error',this.apiResponseMsg.danger.msg,this.apiResponseMsg.danger.icon)
      })
    }

    if(item.traget_type == 'url'){
      alert("url")
    }
  }

}
