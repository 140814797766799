import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'progress__007_001',
  templateUrl: './progress__007_001.component.html',
  styleUrls: ['./progress__007_001.component.scss']
})
export class Progress__007_001 implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
