import { Injectable } from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {Router} from '@angular/router';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class InterceptService implements HttpInterceptor {

  constructor(private router: Router) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(req).pipe(
      catchError(this.handleAuthError)

    )

       
  }

    private handleAuthError(err: HttpErrorResponse): Observable<any> {
        if (err.status === 401 ) {
            // this.router.navigateByUrl(`/login`);
            // this.router.navigate(['login']);

        }
        return throwError(err);
    }

    

  
}
