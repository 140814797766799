import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { TestServiceService } from '../../../../services/test_service/test-service.service';

@Component({
  selector: 'tabs__002_001',
  templateUrl: './tabs__002_001.component.html',
  styleUrls: ['./tabs__002_001.component.scss']
})
export class Tabs__002_001 implements OnInit {
  tabs;
  tab_with_gps;
  defaultLang: string;
  languages: Array<any> = [];
  form_tabs: FormGroup;
  lang_form: FormGroup;

  constructor(private testService: TestServiceService,
    private fb: FormBuilder,
  ) {
    this.lang_form = fb.group({
      lang: ['',],
    })
  }

  ngOnInit() {
    // this.form_tabs = this.createControl();
    /**Incase of form without groups */
    // this.tabs = this.testService.general_tab_with_form_style();

    /**Incase of form with groups */
    // this.tabs = this.testService.tabStructure_with_form_style_gp;
    // console.log("tab with form Gp => ",this.tab_with_gps)

    /**Incase of group_type languages */
    // console.log("tab with form Gp => ", this.tabs);

    /**In case of form without groups */
    this.form_tabs = this.createControl(this.tabs.tab_list, this.tabs.form_type);

    /**In case of form with groups */

  }

  get value() {
    return this.form_tabs.value;
  }

  /**
   * 
   * @param listOfTabs 
   * @param form_type there are types of form => (1)form without gps (2) form with groups
   */
  createControl(listOfTabs, form_type) {
    console.log("form type =>>>>>>", form_type);
    console.log("list of tabs =>>>>", listOfTabs)
    const group = this.fb.group({});
    if (form_type == 'form_without_gp') {
      listOfTabs.forEach(tab => {
        tab.tab_content.fields.forEach(field => {
          if (field.type === "button") return;
          const control = this.fb.control(
            field.value,
            this.bindValidations(field.validations || [])
          );
          group.addControl(field.name, control);
        })
        console.log("fields =>", tab.tab_content.fields);
      })
    }

    else if (form_type == 'form_with_gp') {
      console.log("form with gp");
      listOfTabs.forEach(tab => {
        tab.tab_content.groups.forEach(gp => {
          if (gp.group_type == 'languages') {
            this.defaultLang = gp.languages[0].value;
            this.assignToLangForm(gp.fields);
            return;
          }
          gp.fields.forEach(field => {
            if (field.type === "button") return;
            const control = this.fb.control(
              field.value,
              this.bindValidations(field.validations || [])
            );
            group.addControl(field.name, control);
          })
        })
        console.log("tabaaaaa", tab.tab_content.groups)
      })

    }

    return group;

  }

  bindValidations(validations: any) {
    if (validations.length > 0) {
      const validatorsList = [];
      validations.forEach(validator => {
        // validList.push(valid.validator);
        if (validator.name == 'minlength') {
          validatorsList.push(Validators.minLength(validator.validator_value));
        }
        else if (validator.name == 'maxlength') {
          validatorsList.push(Validators.maxLength(validator.validator_value));
        }
        else {
          validatorsList.push(Validators[`${validator.name}`]);
        }
      });
      return Validators.compose(validatorsList);
    }
    return null;
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  onSubmit(formValue) {
    console.log("formValue from Tabs =>", formValue);
    console.log("languages => ", this.languages);
    formValue['language_fields'] = this.languages
    console.log("formValue after the languages=> ", formValue);
  }

  submitLangFields(langFieldsValue) {
    console.log("lang value => ", langFieldsValue);
    
//       var obj = {"1":5,"2":7,"3":0,"4":0,"5":0,"6":0,"7":0,"8":0,"9":0,"10":0,"11":0,"12":0}
// var result = Object.keys(obj).map(function(key) {
//   return [Number(key), obj[key]];
// });

// let arrayResault = Object.values(langFieldsValue).map(function(value) {
//   console.log("key => ",[value)
//   return value;
// })
// console.log("arrayResault =>",arrayResault);

    this.languages.push(langFieldsValue);
  }

  assignToLangForm(fields) {
    console.log("the default language =>",this.defaultLang)
    const group = this.fb.group({});
    console.log("languages fields =>>>>?//?/////", fields);

    fields.forEach(field => {
      if (field.type === "button") return;
      const control = this.fb.control(
        field.value,
        this.bindValidations(field.validations || [])
      );
      group.addControl(field.name, control);
    })
    const lang = this.fb.control('');
    group.addControl('lang', lang);
    this.lang_form = group;
  }

  clearFields() {
    console.log("change Language")
  }
}

