import { FieldConfig } from './../../../models/field-config.interface';
import { FormGroup } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss']
})
export class DateComponent implements OnInit {
  formGp: FormGroup;
  field: FieldConfig;
  datawithNewFormat;
  constructor() { }

  ngOnInit() {
  }
  /**
     * this function allows you change the format of date
     * @param dateFormControlName :formControlName of date input
     */
    DateFormat(dateFormControlName){
      console.log("dataFormControlName =>",dateFormControlName)
      let dateValue = this.formGp.get(`${dateFormControlName}`).value;
      console.log("datavalue=> ",dateValue)
      const selectesDateTime = new Date(Date.parse(dateValue) - (new Date()).getTimezoneOffset()* 60000).toISOString().split('T')[0]
      console.log("selectDateTime =>",selectesDateTime);
      // this.formGp.get(`${dateFormControlName}`).reset();
      // this.datawithNewFormat[`${dateFormControlName}`] = selectesDateTime;
      // console.log(this.datawithNewFormat)
    
  }

}
