import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BASE_API_URL} from '../../../environments/environment';
import { ApiConstData } from '../../../consts/ApiConstData';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class UserService {
  updateProfileRef: BehaviorSubject<any>;
  updateProfile: string = '';
  constructor(private httpClient: HttpClient,
              private apiConstants: ApiConstData,) { 
                this.updateProfileRef = new BehaviorSubject(this.updateProfile);
              }

  getUserInfo(){
    return JSON.parse(localStorage.getItem("user-info"));
  }

updateUserData(userInfo)
  {
    this.updateProfileRef.next(userInfo);
  }
  getUserAppID(){
  }

  ChangeEmail(FormData){
    
    let newobj=
    {...this.apiConstants.getHeaderandBodywithoutbackend().apiBody, ...FormData}
    // console.log("new obj => ",newobj)
    return this.httpClient.post(`${BASE_API_URL}/change_email`,newobj,{headers: this.apiConstants.getHeaderandBodywithoutbackend().apiHeaders})
  }

  ChangePassword(FormData){
    
    let newobj=
    {...this.apiConstants.getHeaderandBodywithoutbackend().apiBody, ...FormData}
    return this.httpClient.post(`${BASE_API_URL}/users/update`,newobj,{headers: this.apiConstants.getHeaderandBodywithoutbackend().apiHeaders})
  }

  GetProfileInfo(){
    return this.httpClient.post(`${BASE_API_URL}/details`,this.apiConstants.getHeaderandBodywithoutbackend().apiBody,{headers: this.apiConstants.getHeaderandBody().apiHeaders})
  }

  updateprofile(FormData){
    let newobj=
    {...this.apiConstants.getHeaderandBodywithoutbackend().apiBody, ...FormData}
    return this.httpClient.post(`${BASE_API_URL}/users/update`,newobj,{headers: this.apiConstants.getHeaderandBodywithoutbackend().apiHeaders})
  }

  getcountries(){
    return this.httpClient.post(`${BASE_API_URL}/countries`,this.apiConstants.getHeaderandBodywithoutbackend().apiBody,{headers: this.apiConstants.getHeaderandBodywithoutbackend().apiHeaders})

  }
  getcities(FormData){
    let newobj=
    {...this.apiConstants.getHeaderandBodywithoutbackend().apiBody,
      "country_id":FormData}
    return this.httpClient.post(`${BASE_API_URL}/cities`,newobj,{headers: this.apiConstants.getHeaderandBodywithoutbackend().apiHeaders})
  }

}
