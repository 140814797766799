import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'pipeline__012_001_001',
  templateUrl: './pipeline__012_001_001.component.html',
  styleUrls: ['./pipeline__012_001_001.component.scss']
})
export class Pipeline__012_001_001 implements OnInit {
  // @Input() apiUrl: string
  apiLink;
  apiParameters;
  constructor() { }
  ngOnInit() {
    console.log("apiUrl from pipeline full=> ",this.apiLink)
  }

}
