import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FieldConfig } from '../../models/field-config.interface';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';

@Component({
  selector: 'penta-dynmaic-form',
  templateUrl: './penta-dynmaic-form.component.html',
  styleUrls: ['./penta-dynmaic-form.component.scss']
})
export class PentaDynmaicFormComponent implements OnInit {
  @Input() fields= [];
  @Output() submit: EventEmitter<any> = new EventEmitter<any>();

  formGp:FormGroup;
  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    this.formGp = this.createControl();
    // this.createFormControl(this.fields)
  }

  get value() {
    return this.formGp.value;
  }

  // createFormControl(fieldsList){
  //   let controlGp = {};
  //   let validatorsList = []
  //   fieldsList.forEach(element => {
  //     element.validations.forEach(validator =>{
  //       if(validator.name == 'minlength'){
  //         validatorsList.push(Validators.minLength(validator.validator_value));
  //       }
  //       else if(validator.name == 'maxlength'){
  //         validatorsList.push(Validators.maxLength(validator.validator_value));
  //       }
  //       else {
  //         validatorsList.push(Validators[`${validator.name}`]);
  //       }
      
  //     })

  //     controlGp[element.name]= new FormControl('',Validators.compose(validatorsList));

  //   });
  //   this.formGp = new FormGroup(controlGp);
  // }

  onSubmit(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.formGp.valid) {
      console.log("form valid");
      this.submit.emit(this.formGp.value);
    } else {
      console.log("form not valid");
      this.validateAllFormFields(this.formGp);
    }
  }


  createControl() {
    const group = this.fb.group({});
    this.fields.forEach(field => {
      if (field.type === "button") return;
      const control = this.fb.control(
        field.value,
        this.bindValidations(field.validations || [])
      );
      group.addControl(field.name, control);
    });
    return group;
    
  }


  bindValidations(validations: any) {
    if (validations.length > 0) {
      const validatorsList = [];
      validations.forEach(validator => {
        // validList.push(valid.validator);
        if(validator.name == 'minlength'){
          validatorsList.push(Validators.minLength(validator.validator_value));
        }
        else if (validator.name == 'maxlength') {
          validatorsList.push(Validators.maxLength(validator.validator_value));
      }
      else {
          validatorsList.push(Validators[`${validator.name}`]);
      }
      });
      return Validators.compose(validatorsList);
    }
    return null;
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }



  }
  





