import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
declare const Pusher: any;

@Injectable({
  providedIn: 'root'
})
export class PusherService {
  pusher: any;
  channel: any;
  constructor(private http: HttpClient) { 
    this.pusher = new Pusher(environment.pusher.key, {
      cluster: environment.pusher.cluster,
      encrypted: true,
    });
    this.channel = this.pusher.subscribe(`private_notifications_channel_${JSON.parse(localStorage.getItem('user-info')).id}`);
    console.log("channel=> ",`private-notifications-channel.${JSON.parse(localStorage.getItem('user-info')).id}`)
  }
}
