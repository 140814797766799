import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'card__003_006',
  templateUrl: './card__003_006.component.html',
  styleUrls: ['./card__003_006.component.scss']
})
export class Card__003_006 implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
