import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../services/global/global.service';
import { Router } from '@angular/router';
import { MenuService } from '../../services/menu/menu.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
// import { LayoutModalComponent } from '../../pages/layouts/layout-modal/layout-modal/layout-modal.component';

@Component({
  selector: 'ngx-smart-table-cell',
  templateUrl: './smart-table-cell.component.html',
  styleUrls: ['./smart-table-cell.component.scss']
})
export class SmartTableCellComponent implements OnInit {
  cellData:object;
  constructor(private global:GlobalService,
              private router: Router,
              private menuService:MenuService,
              private modal: NgbModal,
    ) { }

  ngOnInit() {
      this.cellData = this.global.cellSettings;
      // console.log("cell settings => ", this.cellData);
  }

  /**
   * This function fetch settings of item that draw in table to determin its target action 
   * @param settings: settings of item in smart table 
   *  settings:{
        target_type: "",=>
   *    target_layout_id: "",
        target_url: "",
        title: "",
   *    content_id: "", (not used)
        target_action_id: "",(not used)
        target_content: "",(not used)
   * }
   */
  
   fetchTargetAction(settings){
    /*Incase Layout Page*/
    if(settings.target_type == 'layout'){
      let layoutLink = this.menuService.getLayoutByID(settings.target_layout_id);
      this.router.navigate([layoutLink])
    }
    /*Incase Modal*/
    if(settings.target_type == 'modal'){
      // const activeModal = this.modal.open(LayoutModalComponent, { size: 'lg', container: 'nb-layout' });
      // activeModal.componentInstance.pageID = settings.page_id;
    }
    /*Incase external Url*/
    if(settings.target_type == 'url'){
      let URL = settings.target_url;
      window.open(URL, "_blank");
    }
  }

}
