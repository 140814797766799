import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../../../services/global/global.service';
import { GlobalFunctions } from '../../../../../consts/global-functions';
import { Configurations } from '../../../../../consts/configurations';

@Component({
  selector: 'statistics__005_002',
  templateUrl: './statistics__005_002.component.html',
  styleUrls: ['./statistics__005_002.component.scss']
})
export class Statistics__005_002 implements OnInit {
  componentList;
  apiLink;
  apiParameters;
  children;
  moduleID;
  structure = {
    title: "Top Deals",
    data: [
    {
      icon:'fas fa-dollar-sign',
      text:'any Words',
      number:'122.000.000',
      ref:'$',
      target:'praesentium ',
      person:{name:'hagar'}
    },
    {
      icon:'fas fa-dollar-sign',
      text:'any Words',
      number:'122.000.000',
      ref:'$',
      target:'praesentium ',
      person:{name:'hagar'}
    },
    {
      icon:'fas fa-dollar-sign',
      text:'any Words',
      number:'122.000.000',
      ref:'$',
      target:'praesentium ',
      person:{name:'hagar'}
    },
    ]
  }
  constructor(
    public config: Configurations,
    private GlobalFunctions: GlobalFunctions,
    private globalVariables: GlobalService,
  ) { }

  ngOnInit() {
    console.log("Statistics__005_004 apiLink",this.apiLink)
    console.log("Statistics__005_004 apiParameters",this.apiParameters)
    console.log("Statistics__005_004 children=> ",this.children)
    console.log("statistics__005_002")
    this.globalVariables.setCompLoadedNum();

  }

}
