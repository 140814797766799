import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { PipelineService } from '../../../../../../services/pipeline/pipeline.service';
import swal from 'sweetalert';
import { GlobalService } from '../../../../../../services/global/global.service';

@Component({
  selector: 'pipeline_modal__012_001_005',
  templateUrl: './pipeline_modal__012_001_005.component.html',
  styleUrls: ['./pipeline_modal__012_001_005.component.scss']
})
export class Pipeline_modal__012_001_005 implements OnInit {
  @Input() title;
  @Input() ParentTaskID;
  @Input() TaskID;
  @Output() StatusChange = new EventEmitter();
  @Output() DescChange = new EventEmitter();
  stages;
  element;

  @Input()newStatus;
  Desc;
  StatusList=[
    // {id:"1",name:"Done",color:"#61BD4F",active:false},
    // {id:"2",name:"Pending",color:"#FF9F1A",active:false},
    // {id:"3",name:"New",color:"#EB5A46",active:false},
    // {id:"4",name:"Active",color:"#C377E0",active:false}

  ];
  AllStatusList;
  label;
  selected=false;
  currentElement;
  constructor(private modalService: NgbModal,private statusService:PipelineService,
    private globalVariables: GlobalService)
   { }

  ngOnInit() {

    this.statusService.getAllStatus().subscribe(response=>
      {
        this.StatusList=response.content;
        console.log("StatusList >>>>>>>>>",this.StatusList);

      })
   this.label=this.newStatus;
   this.Desc=this.label.description;
  //  this.selected=true;
   console.log("Label>>>>",this.label);
  }
  close()
  {

    this.modalService.dismissAll();
  }
  showLabel()
  {
    $(".color-status").css("display","block");
  }
  closeLabel()
  {
    $(".color-status").css("display","none");

  }
  addme(item,i){
   console.log(item);
    this.label=item;
   console.log("label >>>>>",this.label , i);

   for (let index = 0; index <this.StatusList.length; index++) {
     
    this.StatusList[index].active=false;
   }
   this.StatusList[i].active=true;

   
   this.selected=true;
   this.StatusChange.emit(item);
   console.log("id >>>>>>>",this.TaskID , item.id)

   this.statusService.updateStatus(this.TaskID,item.id).subscribe(response=>
    {
      console.log("Update >>>>>>>",response)
    },error=>{console.log("Error-----------",error)})

  }
  save()
  {
    this.statusService.updateDescription(this.TaskID,this.Desc).subscribe(response=>
      {
        console.log("Description >>>>>",response );
        
      });
      this.label.description=this.Desc;
      console.log("Description -------->>>>>",this.label );

      this.DescChange.emit(this.label);
      this.close();
  }
  delete()
  {
    this.stages = this.globalVariables.trelloTasks;
    console.log("Index => ", this.ParentTaskID);
    this.element=this.stages[this.ParentTaskID].tasks.indexOf(this.stages[this.ParentTaskID].tasks.find(element=>
      element.request_id==this.label.request_id));
    console.log("Deleted Element => ", this.element);



    this.statusService.deleteTask(this.TaskID).subscribe(response=>
      {
        console.log("Delete -------->>>>>",response.status.code );

        if(response.status.code == 200)
        {
   swal({
        title: "Are you sure?",
        text: "Are you sure that you want toDelete this task?",
        icon: "warning",
        dangerMode: true,
      })
      .then(willDelete => {
        if (willDelete) {
          this.globalVariables.trelloTasks[this.ParentTaskID].tasks.splice(this.element,1);
          console.log("this.globalVariables.trelloTasks >>",this.globalVariables.trelloTasks);
          
          swal("Deleted!", "Your imaginary task has been deleted!", "success");
        };
         this.close();
      });

    

        }
   


      })
  }

}
