import { Component, OnInit } from '@angular/core';
import { EventSettingsModel } from '@syncfusion/ej2-angular-schedule';


@Component({
  selector: 'calendar__016_002',
  templateUrl: './calendar__016_002.component.html',
  styleUrls: ['./calendar__016_002.component.scss']
})
export class Calendar__016_002 implements OnInit {
  setView1 = 'Month'
  public eventObject:EventSettingsModel = {
    dataSource: [{
      Subject:"Testing1",
      StartTime: new Date(2020,2,13,3,0),
      EndTime:new Date(2020,2,13,5,0)
    },
    {
      Subject:"Testing2",
      StartTime: new Date(2020,2,15,3,0),
      EndTime:new Date(2020,2,20,5,0)
    },
  ]
  }
  constructor() { }

  ngOnInit() {
  }

}
