import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConstData } from '../../../../../../consts/ApiConstData';
import { Configurations } from '../../../../../../consts/configurations';
import { GlobalFunctions } from '../../../../../../consts/global-functions';
import { GlobalService } from '../../../../../services/global/global.service';

@Component({
  selector: 'table_basic__014__001_001',
  templateUrl: './table_basic__014__001_001.component.html',
  styleUrls: ['./table_basic__014__001_001.component.scss']
})
export class Table_basic__014__001_001 implements OnInit {
  apiLink;
  apiParameters;
  children;
  moduleID;
  table ={
      title:"",
      header:[],
      tableData:[]
  }
  // table = {
  //   header:['Category','Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'],
  //   tableData:[
  //     {
  //       ref:"normal",
  //       head:'Deposite',
  //       data: [50.00,50.00,50.00,50.00,50.00,50.00,50.00,50.00,50.00,50.00,50.00,50.00,],
  //     },
  //     {
  //       ref:"normal",
  //       head:'Invoices',
  //       data: [50.00,50.00,50.00,50.00,50.00,50.00,50.00,50.00,50.00,50.00,50.00,50.00,],
  //     },
  //     {
  //       ref:"normal",
  //       head:'Sales',
  //       data: [50.00,50.00,50.00,50.00,50.00,50.00,50.00,50.00,50.00,50.00,50.00,50.00,],
  //     },
  //     {
  //       ref:"normal",
  //       head:'Bills',
  //       data: [50.00,50.00,50.00,50.00,50.00,50.00,50.00,50.00,50.00,50.00,50.00,50.00,],
  //     },
  //     {
  //       ref:"normal",
  //       head:'Uncategories',
  //       data: [50.00,50.00,50.00,50.00,50.00,50.00,50.00,50.00,50.00,50.00,50.00,50.00,],
  //     },
  //     {
  //       ref:"footer",
  //       head:'Total',
  //       data: [50.00,50.00,50.00,50.00,50.00,50.00,50.00,50.00,50.00,50.00,50.00,50.00,],
  //     },
  //   ]
  // }
//******Key and value */
  // table = {
  //   title:"Table of test",
  //   header:[],
  //   tableData:[
  //     {
  //       ref:"normal",
  //       head:'Deposite',
  //       data: ['50.00$'],
  //     },
  //     {
  //       ref:"normal",
  //       head:'Deposite',
  //       data: ['50.00$'],
  //     },
  //     {
  //       ref:"normal",
  //       head:'Deposite',
  //       data: ['50.00$'],
  //     },
  //     {
  //       ref:"normal",
  //       head:'Deposite',
  //       data: ['50.00$'],
  //     },
  //     {
  //       ref:"normal",
  //       head:'Deposite',
  //       data: ['50.00$'],
  //     },
  //     {
  //       ref:"normal",
  //       head:'Deposite',
  //       data: ['50.00$'],
  //     },
  //   ]
  // }

  // table = {
  //   title:"Table of test",
  //   header:['test1','test2','test3'],
  //   tableData:[
  //     {
  //       ref:"normal",
  //       head:'',
  //       data: ['50.00$','50.00$','50.00$'],
  //     },
  //     {
  //       ref:"normal",
  //       head:'',
  //       data: ['50.00$','50.00$','50.00$'],
  //     },
  //     {
  //       ref:"normal",
  //       head:'',
  //       data: ['50.00$','50.00$','50.00$'],
  //     },
  //     {
  //       ref:"normal",
  //       head:'',
  //       data: ['50.00$','50.00$','50.00$'],
  //     },
  //     {
  //       ref:"normal",
  //       head:'',
  //       data: ['50.00$','50.00$','50.00$'],
  //     },
  //     {
  //       ref:"normal",
  //       head:'',
  //       data: ['50.00$','50.00$','50.00$'],
  //     }
  //   ]
  // }
  constructor(private httpClient:HttpClient,
              private apiConst:ApiConstData,
              public config: Configurations,
              private GlobalFunctions: GlobalFunctions,
              private globalVariables: GlobalService,


    ) { }

  ngOnInit() {
    console.log("Table_basic__014__001_001")
    this.httpClient.post<any>(this.apiLink,this.apiParameters,{headers:this.apiConst.getHeaderandBody().apiHeaders}).subscribe(response=>{
      console.log("respnse table basic=> ",response)
      this.table = response;
      this.globalVariables.setCompLoadedNum();
    },error=>{
      this.globalVariables.setCompLoadedNum();
      this.GlobalFunctions.showToastr('danger', 'Error', this.config.apiResponseMsg.danger.msg, this.config.apiResponseMsg.danger.icon)
    })
  }

}
