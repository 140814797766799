import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Input } from '@angular/core';


@Component({
  selector: 'ngx-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  @Input() formStructure;
  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit() {
    console.log("da5al el modal")
    console.log('====================================');
    console.log("fields from modal=>",this.formStructure)
    console.log('====================================');
  }
  closeModal(){
    this.activeModal.close();
  }
  formStatus(event){
    console.log("form status=> ",event)
  }

}
