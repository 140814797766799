import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-penta-block-shape2',
  templateUrl: './penta-block-shape2.component.html',
  styleUrls: ['./penta-block-shape2.component.scss']
})
export class PentaBlockShape2Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
