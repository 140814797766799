import { HttpHeaders } from '@angular/common/http';
export class ApiConstData {
    
    getHeaderandBody(){
        if(localStorage.getItem('user-info')!= null){
            var userInfo =JSON.parse(localStorage.getItem('user-info'));
            let header = new HttpHeaders().set("Content-Type",'application/json')
                                        .set("access-token",userInfo.api_token)
                                        .set('backend','1');
            let headerwithoutBE = new HttpHeaders().set("Content-Type",'application/json')
                                .set("access-token",userInfo.api_token)
                                
            let body = {"application_id":userInfo.application_id,
                        // "user_id":userInfo.id,
                        "lang_id":2,
                    }
            let headerNbody = {'apiHeaders':header,'apiBody':body,'headerwithoutBE': headerwithoutBE}
            return headerNbody;
        }
        else{
            return;
        }
    }

    getHeaderandBodywithoutbackend(){
        if(localStorage.getItem('user-info')!= null){
            var userInfo =JSON.parse(localStorage.getItem('user-info'));
            let header = new HttpHeaders().set("Content-Type",'application/json')
                                        .set("access-token",userInfo.api_token)
                                        // .set('backend','1');
            let headerwithoutBE = new HttpHeaders().set("Content-Type",'application/json')
                                .set("access-token",userInfo.api_token)
                                
            let body = {"application_id":userInfo.application_id,
                        // "user_id":userInfo.id,
                        "lang_id":2,
                    }
            let headerNbody = {'apiHeaders':header,'apiBody':body,'headerwithoutBE': headerwithoutBE}
            return headerNbody;
        }
        else{
            console.log("898989")
            return;
        }
    }
   
}