import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'penta-description-input',
  templateUrl: './description-input.component.html',
  styleUrls: ['./description-input.component.scss']
})
export class DescriptionInputComponent implements OnInit {
  @Input() data;
  constructor() { }

  ngOnInit() {
  }

}
