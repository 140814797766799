import { Component, OnInit, ViewChild, ViewContainerRef, ComponentFactoryResolver, Input } from '@angular/core';
import { GlobalService } from '../../../../../../services/global/global.service';



@Component({
  selector: 'pipeline_list__012_001_003',
  templateUrl: './pipeline_list__012_001_003.component.html',
  styleUrls: ['./pipeline_list__012_001_003.component.scss']
})
export class Pipeline_list__012_001_003 implements OnInit {
  container = document.getElementById('drag-area-container');
  addListBtn = document.getElementById('add-a-list-btn');
  saveListBtn = document.getElementById('save-list-btn');
  saveListBox = document.getElementById('save-list-box');
  listInputBox = document.getElementById('list-input-box');
  closeBox = document.getElementById('close-list-btn');
  textValue;
  count:number = 0;
  @Input() apiLink;
  @Input() apiParameters;
  @ViewChild('messagecontainer', { read: ViewContainerRef }) entry: ViewContainerRef;
  constructor(private resolver: ComponentFactoryResolver,
              private global: GlobalService
    ) { }
  
  componentRef: any;

  ngOnInit() {

  }
  /**
   * create list of task
   * @param inputID => input that write the name of list on it 
   * 
   */
  createList(inputID) {

    // console.log("create component")
    // this.entry.clear();
    // const factory = this.resolver.resolveComponentFactory(PentaTrelloContainerComponent);
    // const componentRef = this.entry.createComponent(factory);

    // this.textValue = "test"
    // componentRef.instance.textValue = this.textValue;

    // componentRef.instance.count = 15;

    // this.count+=1;
    this.textValue = $(`#${inputID}`).val();
    this.global.trelloTasks.push({'listName':this.textValue,'index':this.count,'cards':[]});
   
    this.count+=1;
    $(`#${inputID}`).val('');


}
  // ngAfterViewInit(){
  //   require('../../../../assets/scripts/script.js');
  // }

  showListWidget() {
    console.log("show list")
    $("#save-list-box").css('display','inline-block')
    $("#add-a-list-btn").css('display','none')
  }

  hideListWidget() {
    $("#save-list-box").css('display','none');
    $("#add-a-list-btn").css('display','inline-block');
    $("#list-input-box").val('');
  }


  // createList(inputID) {
  //   this.count+=1;
  //   console.log(inputID)
  //   let textValue  = $(`#${inputID}`).val();
  //   console.log("textValue => ", textValue);
  //   $("#lists-section").append(`
  //         <div class="add-card-container" id="list-container-${this.count}">
  //         <div class="list-title">
  //             <a class="fa fa-ellipsis-h ellipsis" id="edit-0"></a>
  //             <div contenteditable="true" class="text">${textValue}</div>
  //         </div>
  //         <div class="list-inner-scroll" id="list-inner-scroll-${this.count}"></div>
  //         <div class="new-card-widget-container" id="new-card-widget-container-${this.count}" >
  //             <input class="new-card-widget-input" id="new-card-widget-input-${this.count}" autofocus="autofocus" placeholder="please add the card name...">
  //             <button (click)= "addCardToList(list-inner-scroll-${this.count},new-card-widget-input-${this.count})" class="new-card-widget-btn-save" id="new-card-widget-btn-save-${this.count}" >save</button>
  //             <button class="new-card-widget-btn-cancel fa fa-times" id="new-card-widget-btn-cancel-${this.count}"></button>
  //         </div><a href="#" class="add-card" id="add-${this.count}">Add a new card...</a></div>
    
  //         `)
  //   $(`#${inputID}`).val('');
  // }

  /**
   * when click on save btn when add new card
   * @param listID => the container of all cards inside list
   * @param inputID => the input id of input that write the card text
   */
  addCardToList(listID,inputID) {
    console.log("listID => ",listID);
    console.log("inputID => ",inputID)

    let textValue = $(`#${inputID}`).val();
    $(`#${listID}`).append(`
        <div class="list-item" contenteditable="">${textValue}</div>
    `)
  }


}
