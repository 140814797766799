import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'uploader__008_001',
  templateUrl: './uploader__008_001.component.html',
  styleUrls: ['./uploader__008_001.component.scss']
})
export class Uploader__008_001 implements OnInit {
  @Input() typeOfUploader: string;
  @Output() uploadedFiles: EventEmitter<any> = new EventEmitter<any>()
  fileName: string;
  fileSize: number;
  maxImageSize: number = 1024;
  image;
  filesArr = [];
  test = "40%";
  imgExt = ".gif, .jpeg, .jpg, .png";
  fileExt = '.csv, .docx, .xlsx'
  uploaderTypeEx:string;
  formData = new FormData();
  count = 0;



  constructor() { }

  ngOnInit() {
    console.log("type of files=> ",this.typeOfUploader)
   
    switch (this.typeOfUploader){
      case 'images':{
        this.uploaderTypeEx = this.imgExt;
        break;
      } 

      case 'files' :{
        this.uploaderTypeEx = this.fileExt
      }

    }
  }
  checkFileType(event) {
    var files = event.addedFiles;
    for (let i = 0; i < files.length; i++) {
      let extension = files[i].name.substring(files[i].name.lastIndexOf('.') + 1).toLowerCase();
      let imagecheck = this.imgExt.indexOf(extension);
      console.log("check => ", imagecheck);
      if (imagecheck < 0) {
        console.log("not image")
        this.UploadedFiles(files[i]);
      }
      else {
        console.log("image")
        this.readImage(files[i]);
      }
    }
  }

  readImage(uploadedImage) {
    console.log("uploaded images=> ", uploadedImage)
    this.fileName = uploadedImage.name;
    this.fileSize = uploadedImage.size;
    var reader = new FileReader();
    reader.onload = this.handleReaderLoaded.bind(this);
    reader.readAsBinaryString(uploadedImage);
  }

  handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result
    this.image = 'data:image/jpeg;base64,' + btoa(binaryString);
    this.collectUploadedFiles(this.fileName,this.fileSize,this.image);
    console.log("image Name => ", this.filesArr);
    this.uploadedFiles.emit(this.filesArr)
  }

  collectUploadedFiles(fileName,fileSize,file?) {
    if (fileSize / 1000 > this.maxImageSize) {
      this.filesArr.push({
        'name': fileName,
        'size': fileSize,
        'index': this.filesArr.length,
        'image': file,
        'class': 'red-class',
      });
    }
    else {
      this.filesArr.push({
        'name': fileName,
        'size': fileSize,
        'index': this.filesArr.length,
        'image': file,
        'class': 'default',
      });
    }
  }

  closebtn(index) {
    console.log("closebtn")
    console.log("value2")
    this.filesArr.splice(index, 1);
    $.each(this.filesArr, function (id, value) {
      value.index = id;
    });
    console.log("all images =>", this.filesArr);
    this.uploadedFiles.emit(this.filesArr)
  }

  UploadedFiles(file) {
    this.count += 1;
    console.log("event files= >", file);
    this.collectUploadedFiles(file.name,file.size);

    this.formData.append(`file_${this.count}`, file, file.name);
    console.log("formData => ", this.formData.get(`file_${this.count}`));
    this.uploadedFiles.emit(this.formData);
  }
  
}
