import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NbLayoutDirectionService } from '@nebular/theme';
import { HttpClient } from '@angular/common/http';
import { ApiConstData } from '../../../consts/ApiConstData';
import { BASE_API_URL } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  baseUrl = BASE_API_URL;
  constructor(
    private translate: TranslateService,
    private directionService: NbLayoutDirectionService,
    private httpClient: HttpClient,
    private apiConst: ApiConstData
  ) { }

  /**
 * this function sets language and Direction of Application depend on Choosing language from select menu and add to lang_id in localStorage
 * ana momken badl ma a3mel el if a5aly assamy el files bta3et el languages b el if 1 for english and 2 for arabic
 */
  setLangNDir(langID) {
    console.log("setLANDir")
    console.log(langID)
    let language;
    localStorage.setItem("lang_id",`${langID}`)
    if(langID == '1'){
     language = 'en'
     this.getNewDirection('en')

    }
    if(langID == '2'){
      language = 'ar'
      this.getNewDirection('ar')

    }
    this.translate.setDefaultLang(`${language}`);
    console.log(language)
  }

  // convertDir(langID){
  //   console.log("/*/*/* convert Dir")
  //   localStorage.setItem("lang_id",langID);
  //   console.log(langID);
  //   var language;
  //   if(langID == '1'){
  //     language = 'ar'
  //   }
  //   if(langID == '2'){
  //     language = 'en'
  //   }
  //   console.log("language=> ",language)
  //   this.translate.setDefaultLang('ar');
  //   this.getNewDirection();
  // }
  /**
   * This Function gets new Direction of application 
  */
  getNewDirection(language) {
    console.log("dirhhhh => ", language)
    this.translate.getTranslation(language).subscribe(data => {
      let translationData = data;
      let dir = translationData.SETTINGS.DIR;
      console.log("dir from service ::::::::;", data)
      this.toggleDirection(dir);
    })
  }
   /**
   * This Function set the New Direction depend on choosing from select menu 
   * @param newDirection: the direction when change the language 
   * if language == ar the newDirection = rtl, if language == en the newDirection = ltr
   * and the get the newDirection from (getNewDirection Function)
   */
  toggleDirection(newDirection) {
    this.directionService.setDirection(newDirection);
    console.log("set Direction => ", newDirection)
  }

  getLangID(){
    return localStorage.getItem("lang_id")
  }
  changeLang(lang_id){
    return this.httpClient.post(`${this.baseUrl}/change_lang`,{"lang_id":lang_id},{headers:this.apiConst.getHeaderandBody().apiHeaders})
  }

}
