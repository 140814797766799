import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'penta-view-key-value',
  templateUrl: './view-key-value.component.html',
  styleUrls: ['./view-key-value.component.scss']
})
export class ViewKeyValueComponent implements OnInit {
  @Input() data;

  Values:any[] = []
  constructor() { }

  ngOnInit() {
    console.log("The input data ::::::::",this.data)

     this.data.forEach((element) => {
        if (element.value !== null){
          this.Values.push(element)
        }
    });

    console.log("The new array ::::::::",this.Values)

  }

  openLink(fileLink)
  {
    window.open(fileLink,'_blank')
  }

}
