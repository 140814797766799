
import { HttpClient } from '@angular/common/http';
import { TestServiceService } from './../../../../services/test_service/test-service.service';
import { Component, OnInit } from '@angular/core';
import { ApiConstData } from '../../../../../consts/ApiConstData';
import { GlobalFunctions } from '../../../../../consts/global-functions';
import { Configurations } from '../../../../../consts/configurations';
import { GlobalService } from '../../../../services/global/global.service';
import { SweetAlert } from 'sweetalert/typings/core';
import * as _swal from 'sweetalert';
declare var $: any;


const swal: SweetAlert = _swal as any;

@Component({
  selector: 'card__003_002',
  templateUrl: './card__003_002.component.html',
  styleUrls: ['./card__003_002.component.scss']
})
export class Card__003_002 implements OnInit {
  loading: Boolean = false;
  notifications: Array<Object> = [];
  apiLink: string;
  apiParameters: Object;
  moduleID: number;
  constructor(private testService: TestServiceService,
    private apiConstData: ApiConstData,
    private httpClien: HttpClient,
    private GlobalFunctions: GlobalFunctions,
    public config: Configurations,
    private globalVariables: GlobalService,
  ) { }

  ngOnInit() {
    this.httpClien.post<any>(`${this.apiLink}`, this.apiParameters, { headers: this.apiConstData.getHeaderandBody().apiHeaders }).subscribe(response => {
      this.notifications = response.notification_structure;
      console.log("cuurent_notificationStructure=> ", this.notifications);

      this.globalVariables.setCompLoadedNum();
      console.log("card__003_002_CompLoadedNum=> ", this.globalVariables.compLoadedNum);

    }, error => {
      this.globalVariables.setCompLoadedNum();
      console.log("card__003_002_CompLoadedNum=> ", this.globalVariables.compLoadedNum);

      this.GlobalFunctions.showToastr('danger', 'Error', this.config.apiResponseMsg.danger.msg, this.config.apiResponseMsg.danger.icon)
    })
  }

  action(button, btnIndex, notificationIndex) {
    console.log("btn_index=> ", btnIndex);
    console.log("notificationIndex=> ", notificationIndex);

    console.log("button=> ", button);
    swal({
      title: "",
      text: `${button.confirm_msg}`,
      icon: "info",
      dangerMode: true,
      buttons: ["الغاء", "نعم"],
    }).then(success => {

      if (success){
        console.log("success deleting");
        this.loading = true;
        $(`#loading_${notificationIndex}_${btnIndex}`).removeClass('display--none');
        this.httpClien.post(`${button.api}`, button.parameters, { headers: this.apiConstData.getHeaderandBody().apiHeaders }).subscribe((response: any) => {
          if (response.status.code == 200) {
            document.getElementById('actionsbtns').style.display = 'none';
              //  this.notifications.splice(notificationIndex, 1);
           
            this.GlobalFunctions.showToastr('success', '', `${response.status.message}`, this.config.apiResponseMsg.success.icon)
          }
          else {
            this.GlobalFunctions.showToastr('danger', 'Error', this.config.apiResponseMsg.danger.msg, this.config.apiResponseMsg.danger.icon)
          }
          this.loading = false;
          $(`#loading_${notificationIndex}_${btnIndex}`).addClass('display--none');
  
        }, error => {
          this.loading = false;
  
          $(`#loading_${notificationIndex}_${btnIndex}`).addClass('display--none');
          this.GlobalFunctions.showToastr('danger', 'Error', this.config.apiResponseMsg.danger.msg, this.config.apiResponseMsg.danger.icon)
        })

      }

      else {
         console.log("canceled and api not called")
      }
      


    })

  }

}
